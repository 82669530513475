<template>
  <div>
        <div class="text-h4 py-4 black white--text text-center">CodeTrail</div>
        <div class="my-7 text-center">
            <v-btn @click="$router.go(-1)" outlined icon>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h1>Die Seite wurde nicht gefunden.</h1>
        </div>
    </div>

</template>

<script>

export default {
} 
</script>