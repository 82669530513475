<template>
    <div>
      <v-main class="main">
          <router-view></router-view>
      </v-main>
    </div>
</template>

<script>
export default {
    name: "dashboardLayout",
    data: function() {
      return {}
    },
    created() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
}
</script>

<style scoped>
.main {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
</style>

