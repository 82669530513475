import axios from "axios";
import store from "../store";

export default () => {
    axios.defaults.baseURL = 'https://codetrail.sml.zhaw.ch'; // TODO: put real url here
	//axios.defaults.baseURL = 'http://localhost:8080/';
	
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    const token = localStorage.getItem('profile') == null ? null : JSON.parse(localStorage.getItem('profile')).accessToken;
    const idPlayer = localStorage.getItem('profile') == null ? null : JSON.parse(localStorage.getItem('profile')).id;
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    // idPlayer is only needed for ROLE_USER (Player), TrailAdmin & EventAdmin do not need idPlayer
    if (store.getters.isPlayer) {
        if (idPlayer) {
            axios.defaults.headers.common['idPlayer'] = `${idPlayer}`;
        }
    } else {
        delete axios.defaults.headers.common['idPlayer'];
    }
    return axios
}
