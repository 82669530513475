<template>
  <v-container class="overflow-hidden pa-0 fill-height">
    <v-card class="mt-0 fill-height justify-center" rounded="0">
      <div class="fill-height overflow-y-auto" style="padding-bottom: 80px">
        <v-img
            v-if="!!event.trail.rest.img"
            :src="event.trail.rest.img"
            class="codetrail-event-image"
        ></v-img>
        <v-img
            v-else
            class="d-flex align-center codetrail-event-image"
            left="auto"
            right="auto"
        >
          <v-icon class="d-flex" x-large color="primary">mdi-image-area</v-icon>
        </v-img>

        <v-card-title class="event-title justify-center mb-0 pb-0 font-weight-bold">
          <span class="green--text font-weight-bold">Glückwunsch Team {{trailStatus.groupName}}</span>
        </v-card-title>

        <div class="start-point text-subtitle-2 text-center my-0 font-weight-light">
          Der {{ event.trail.trailName }} wurde beendet
        </div>

        <!-- Group Trail Stats -->
        <v-card-text>
          <div class="d-flex justify-center mt-1">
            <div class="codetrail-stats-wrapper float-left">
              <div>
              <span class="num" :style="'color: ' + colors.primary">
                {{ trailStatus.points }}
              </span>
              </div>
              <div>Punkte</div>
            </div>
            <div class="codetrail-stats-wrapper float-left">
              <div>
              <span class="num" :style="'color: ' + colors.primary">
              {{ totalTimeMin }} min
              </span>
              </div>
              <div>Gesamtzeit</div>
            </div>
            <div class="codetrail-stats-wrapper float-left">
              <div>
              <span class="num" :style="'color: ' + colors.primary">
              {{ totalQuizTimeMin }} min
              </span>
              </div>
              <div>Rätselzeit</div>
            </div>
            <div class="codetrail-stats-wrapper float-left">
              <div>
              <span class="num" :style="'color: ' + colors.primary">
                {{rank}}
              </span>
              </div>
              <div>Rang</div>
            </div>
            <div class="float-end"></div>
          </div>
        </v-card-text>

        <!-- Rangliste -->
        <v-card-title class="my-1">
          <v-spacer></v-spacer>
          <v-text-field
              class="pa-0"
              color="grey"
              v-model="rankingSearch"
              append-icon="mdi-magnify"
              label="Team suchen"
              hide-details
              dense
              style="width: 60px"
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-data-table
            id="rankingTable"
            class="event-ranking overflow-y-auto mx-auto"
            :headers="rankingTableHeaders"
            :items="rankingTable"
            :search="rankingSearch"
            mobile-breakpoint="xl"
            hide-default-footer
            :items-per-page="-1"
            fixed-header
            sort-by="rank"
            :item-class= "rankingRowClasses"
        >
        </v-data-table>

        <v-card-text class="codetrail-bottom-button-wrapper top-shadow">
          <v-row class="challenge-buttons" dense>
            <v-col class="text-no-wrap" cols="6">
              <v-btn
                  color="secondary"
                  class="pa-6 w-100"
                  @click="loadRanking(true)"
              >
                Aktualisieren
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                  color="primary"
                  class="pa-6 w-100"
                  @click="logOut"
              >
                Event verlassen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from "vuex";

export default {
  name: "PlayerScore",
  data: function () {
    return {
      trailStatus: {
        groupName: "",
        groupNumber: 0,
        points: 0,
        totalTime: null,
        totalQuizTime: null
      },
      event: {
        trail: {
          rest: {},
        }
      },
      ranking: [],
      rankingSearch: "",
      rankingTableHeaders: [
        { text: 'Rang', value: 'rank', filterable: false, align: "center" },
        { text: 'Gruppenname', value: 'groupName'},
        { text: 'Punkte', value: 'points', align: "center"},
        { text: 'Rätselzeit', value: 'totalQuizTime', align: "center"},
      ],
      button_text: "Event verlassen",
      ownGroupSelector: "myGroup"
    };
  },
  computed: {
    ...mapGetters([
      "playerId"
    ]),

    colors() {
      return this.$vuetify.theme.themes.light;
    },

    // returns a sorted ranking with rank per team
    rankingTable() {
      let testSelected = 15;

      return [...this.ranking]
          .sort((a, b) => b.points - a.points)
          .map((row, i) => {
            row["rank"] = (i + 1);
            if(i === testSelected) {
              row["selected"] = true;
            }
            row["totalTime"] = Math.round(row["totalTime"] / 60);
            row["totalQuizTime"] = Math.round(row["totalQuizTime"] / 60);
            return row;
          });
    },

    // calculate the rank
    rank() {
      return this.rankingTable.findIndex(row => row.groupNumber === this.trailStatus.groupNumber) + 1;
    },

    totalTimeHHmm() {
      return this.secToHHmm(this.trailStatus.totalTime);
    },

    totalTimeMin() {
      return Math.round(this.trailStatus.totalTime / 60);
    },

    totalQuizTimeHHmm() {
      return this.secToHHmm(this.trailStatus.totalQuizTime);
    },

    totalQuizTimeMin() {
      return Math.round(this.trailStatus.totalQuizTime / 60);
    },
  },
  async mounted() {

    // also load trail status used for stats
    let trailStatus = await this.$api.checkTrailStatus();

    if(typeof trailStatus.data !== "undefined") {
      this.trailStatus = trailStatus.data;
    }
    // also load the ranking
    this.loadRanking().then();
  },
  methods: {
    secToHHmm(sec) {
      return new Date(sec * 1000).toISOString().substr(11, 5);
    },

    async logOut() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/").then();
    },
    async loadRanking(scroll = false) {
      let trailRankingRes = await this.$api.getRanking();
      if(trailRankingRes) {
        this.ranking =  trailRankingRes.data;
        if(scroll) {
          this.rankingScrollTo()
        }
      }
    },
    rankingRowClasses(row) {
      let classes = ""
      if(row.groupNumber === this.trailStatus.groupNumber) {
        classes += this.ownGroupSelector

        // also force to scroll on next tick
        this.$nextTick(() => this.rankingScrollTo());
      }
      return classes;
    },
    rankingScrollTo() {
      let row = document.getElementsByClassName(this.ownGroupSelector)[0];
      if(typeof row !== "undefined") {
        let topPos = row.offsetTop;
        let tableWrapper = document.getElementsByClassName('v-data-table__wrapper')[0];
        tableWrapper.scrollTop = topPos - tableWrapper.clientHeight / 2;
      }
    }
  },
  async created() {
    // first we load the event information
    let res = await this.$api.getEventInfo();
    if(res) {
      this.event = res.data;
    } else {
      this.$router.push("/").then();
    }
  }
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px !important;
}
.event-ranking tr.myGroup {
  color: #0064a6;
  font-weight: 900;
  background-color: #f8f8f8;
}
</style>