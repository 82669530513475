import api from "../../plugins/api";

const state = {
    profile: {}
};
const getters = {
    isAuthenticated: state => !!state.profile.accessToken, // if it's not one of false, null, undefined, 0, -0, NaN, '' => it's true
    accessToken: state => state.profile.accessToken,
    playerId: state => state.profile.id,
    isPlayer: state => {
        if (state.profile.roles) {
            return state.profile.roles.includes('ROLE_USER');
        } else {
            return false
        }
    },
    isTrailAdmin: state => {
        if (state.profile.roles) {
            return state.profile.roles.includes('ROLE_TRAILADMIN');
        } else {
            return false
        }

    },
    isEventAdmin: state => {
        if (state.profile.roles) {
            return state.profile.roles.includes('ROLE_EVENTADMIN');
        } else {
            return false
        }
    }
};
const mutations = {
    setProfile(state, data) {
        state.profile = data
    },
    logout(state) {
        state.profile = {}
    }
};
const actions = {
    async LogIn(context, User) { // credentials getting from input form when login
        var res = await api.signIn(User);
        if(res) {
            context.commit("setProfile", res);
        }
    },
    async LogOut({ commit }) {
        localStorage.removeItem('profile');
        commit('logout');
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};