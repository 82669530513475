<template>
  <v-container>
    <v-btn @click="$router.go(-1)" outlined icon class="mb-2">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-row class="my-1">
      <v-col sm="4" cols="12">
        <v-card>
          <v-img
            v-if="!!challenge.rest.img"
            :src="challenge.rest.img"
            :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
          ></v-img>
          <v-img
            v-else
            class="d-flex align-center"
            left="auto"
            right="auto"
            :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
            ><v-icon class="d-flex" x-large color="primary"
              >mdi-image-area</v-icon
            ></v-img
          >
          <v-card-text class="pa-1">
            <div class="acme ma-3">{{ challenge.rest.name }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="8" cols="12">
        <v-card class="mb-2">
          <v-card-text class="pa-2">
            <v-row class="justify-space-between align-center">
              <v-col md="8" sm="7" cols="12">
                <div class="acme mb-1">Beschreibung inkl. Frage</div>
                <div>
                  <span>
                    {{ challenge.frage }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-2">
          <v-card-text class="pa-2">
            <v-row class="justify-space-between align-center">
              <v-col md="8" sm="7" cols="12">
                <div class="acme mb-1">Lösung</div>
                <div v-if="answerTypeIsTextOrNumber">
                  <span>
                    {{ challenge.solution }}
                  </span>
                </div>
                <div v-else-if="answerTypeIsSingleOrMultipleChoice">
                  <template>
                    <div 
                    v-for="(sol, sol_idx) in getParsedSolution" 
                    :key="sol + '_' + sol_idx"
                    v-html="challenge.rest.possibleSolutions[sol].value"></div>
                  </template>
                </div>
                <div v-else>Nicht definiert</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="my-2">
          <v-card-text class="pa-2">
            <v-row class="justify-space-between align-center">
              <v-col md="8" sm="7" cols="12">
                <div class="acme mb-1">Weitere Infos zur Challenge</div>
                <div>
                  <strong>Antworttyp: </strong>
                  <span>
                    {{ $answerType.getAnswerType(challenge.type) }}
                  </span>
                </div>
                <div>
                  <strong>Anz. Tipps: </strong>
                  <span v-if="!!challenge.tipp" class="ml-3">
                    {{ challenge.tipp.length }} Stk.
                  </span>
                  <span v-else class="ml-3">0 Stk.</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data: function () {
    return {
      done: false,
      challenge: {
        tipp: [],
        rest: {},
      },
    };
  },
  computed: {
    answerTypeIsTextOrNumber(){
      return this.challenge.type==='text' || this.challenge.type==='number';
    },
    answerTypeIsSingleOrMultipleChoice(){
      return this.challenge.type==='singleChoice' || this.challenge.type==='multipleChoice';
    },
    getParsedSolution(){
      var solutionArr = JSON.parse(this.challenge.solution);
      return solutionArr;
    },
  },
  async created() {
    var res = await this.$api.getChallenge(this.$route.params.id);
    this.challenge = res.data;
  },
};
</script>