<template>
  <v-container>
    <v-tabs
      v-model="$store.state.trailTab"
      centered
      class="flex-wrap"
      height="35px"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        href="#tab-1"
        class="grey lighten-4"
        active-class="font-weight-bold"
      >
        Trails
      </v-tab>

      <v-tab
        href="#tab-2"
        class="grey lighten-4"
        active-class="font-weight-bold"
      >
        Challenges
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="$store.state.trailTab">
      <v-tab-item key="0" value="tab-1">
        <v-card flat>
          <v-card-text>
            <div>
              <v-btn
                text
                class="font-weight-bold space-normal d-block"
                @click="addModal = true"
                :small="$vuetify.breakpoint.xs"
              >
                <v-icon left color="primary" :large="!$vuetify.breakpoint.xs">
                  mdi-plus-circle-outline
                </v-icon>
                <span class="ml-2">Trail erstellen</span>
              </v-btn>
            </div>
            <div>
              <v-row class="my-3">
                <v-col md="6" cols="12" v-for="item in trails" :key="item.id">
                  <trail-card v-if="item" :info="item"></trail-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="1" value="tab-2">
        <v-card flat>
          <v-card-text>
            <div>
              <challenge-create @onChallengePost="getChallenges"></challenge-create>
            </div>
            <div>
              <v-row class="my-3">
                <v-col
                  md="6"
                  cols="12"
                  v-for="(item, idx) in challenges"
                  :key="'challenge_' + idx"
                >
                  <challenge-card v-if="item" :info="item"></challenge-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="addModal" scrollable max-width="500">
      <v-card>
        <v-card-title
          class="py-2 primary black--text text-uppercase text-sm-h6 text-subtitle-2 px-1 px-sm-5"
        >
          Trail erstellen
          <v-spacer></v-spacer>
          <v-btn @click="addModal = false" icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form
          ref="createTrailModal"
          style="max-height: 60vh; overflow-y: auto"
        >
          <v-card-text>
            <div class="my-2">
              <label class="acme">*Trail-Name</label>
              <v-text-field
                outlined
                color="primary"
                placeholder="Gib dem Trail einen Namen."
                v-model="trail.trailName"
                required
                dense
                :rules="rules.requiredRules"
                hide-details
                clearable
              >
              </v-text-field>
            </div>
            <div class="my-2">
              <label class="acme">Trail-Ort</label>
              <v-text-field
                outlined
                color="primary"
                placeholder="Wo findet dieser Trail statt?"
                v-model="trail.location"
                dense
                hide-details
                clearable
              >
              </v-text-field>
            </div>
            <div class="my-2">
              <label class="acme">*Zur Verfügung stehende Zeit</label>
              <v-select
                outlined
                style="max-width: 120px"
                color="primary"
                v-model="trail.duration"
                placeholder="Minuten"
                required
                :items="[15, 30, 60, 90, 120, 150, 180, 210]"
                dense
                :rules="rules.requiredRules"
                hide-details
              >
              </v-select>
            </div>
            <div class="my-2">
              <label class="acme">Schwierigkeit</label>
              <v-select
                outlined
                style="max-width: 120px"
                color="primary"
                v-model="trail.difficulty"
                :items="['einfach', 'mittel', 'schwer']"
                dense
                hide-details
              >
              </v-select>
            </div>
            <div class="my-2">
              <label class="acme">Trail-Beschreibung</label>
              <v-textarea
                outlined
                color="primary"
                placeholder="Füge eine Beschreibung des Trails ein."
                v-model="trail.description"
                rows="3"
                hide-details
                clearable
              >
              </v-textarea>
            </div>
            <div class="my-2">
              <label class="acme">Trail-Kurzbeschreibung</label>
              <v-textarea
                outlined
                color="primary"
                maxlength="80"
                hint="Maximal 50 Zeichen zulässig."
                placeholder="Dieser Kurzbeschrieb dient als Vorschau. Die Zeichen sind begrenzt."
                v-model="trail.shortDescription"
                rows="3"
                hide-details
                clearable
              >
              </v-textarea>
            </div>
            <div class="my-2">
              <label class="acme">Trail-Startpunkt</label>
              <v-textarea
                outlined
                color="primary"
                placeholder="Beschreibe, wo dieser Trail beginnt."
                v-model="trail.startPoint"
                rows="3"
                hide-details
                clearable
              >
              </v-textarea>
            </div>
            <div>
              <label class="acme">Bild</label>
              <v-file-input
                @change="onFileChange"
                accept="image/png,image/jpg"
                placeholder="Füge ein Bild für diesen Trail ein."
                outlined
                dense
                clearable
              ></v-file-input>
            </div>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <div class="text-center">
            <div>
              Angaben ohne Stern können auch im Bearbeitungsmodus erstellt
              werden.
            </div>
            <v-btn
              @click="
                postTrail();
                addModal = false;
              "
              color="primary"
              class="br-md mt-3"
              :small="$vuetify.breakpoint.xs"
              >Erstellen</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TrailCard from "../components/trail-card";
import ChallengeCard from "../components/challenge-card";
import ChallengeCreate from "../components/modal/challenge-create";

export default {
  components: { ChallengeCreate, ChallengeCard, TrailCard },
  data() {
    return {
      addModal: false,
      addChallengeModal: false,
      trail: {},
      challenge: {},
      tripForm: {},
      rules: this.$store.getters.rules,
      trails: [],
      challenges: [],
      tips: [
        {
          id: 1,
          name: "Tipp1",
          title: "Tipp1 lorem ipsum",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
        },
        {
          id: 2,
          name: "Tipp1",
          title: "Tipp1 lorem ipsum",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
        },
        {
          id: 3,
          name: "Tipp1",
          title: "Tipp1 lorem ipsum",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
        },
      ],
    };
  },
  methods: {
    onFileChange(e) {
      var file = e;

      if (file) {
        this.createImage(file); // just one file => TODO: multiple files
      } else {
        // else block needed because this function runs on every change => even when removing image
        delete this.trail.img;
      }
    },
    createImage(file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.trail.img = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.onerror = (e) => {
        console.error(e);
      };
    },
    async postTrail() {
      if (this.$refs.createTrailModal.validate()) {
        await this.$api.postTrail(this.trail);
        // in order to immediately fetch just added trail
        const res = await this.$api.getTrails();
        this.trails = res.data;
      } else {
        alert("Bitte alle benötigten Felder ausfüllen.");
      }
    },
    async getChallenges(){
      const res = await this.$api.getChallenges();
      this.challenges = res.data;
    },
  },
  async created() {
    const trail_res = await this.$api.getTrails();
    this.trails = trail_res.data;
    const chlg_res = await this.$api.getChallenges();
    this.challenges = chlg_res.data;
  },
};
</script>