<template>
    <v-card-text class="pa-2">
        <v-row class="justify-space-between align-center">
            <v-col md="8" sm="7" cols="6">
                <div class="acme">Gruppe {{group.gruppe}}</div>
                <div class="text-subtitle-1">
                    <strong>PIN</strong>
                    <span class="ml-3">{{group.pin}}</span>
                </div>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
    export default {
        name: "group-card",
        data() {
            return {
                rules: this.$store.getters.rules,
            }
        },
        props: {
            group: {
                type: Object,
                default: () => {
                }
            }
        }
    }
</script>

<style scoped>

</style>