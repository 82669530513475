import Vuex from "vuex";
import Vue from "vue";
import moment from "moment";
Vue.use(Vuex);
import auth from './modules/auth'

export default new Vuex.Store({
    state: {
        event: {
            trail: {
                trailName: "",
                anzChal: 0,
                rest: {
                    difficulty: "",
                    description: "",
                    startPoint: "",
                    location: ""
                },
            },
            startTime: "00:00",
            endTime: "24:00"
        },
        start_time: null,
        time_left: null,
        isClearInterval: false,
        isEventStarted: false,
        trailTab: 0,
        trailEditTab: 0,
        eventTab: 0,
        trailShowTab: 0,
        closeTime: false,
        startButtonClicked: false,
        isTimerRunning: false,
        snackbar: {
            message: '',
            type: ''
        },
        rules: {
            requiredRules: [(v) => !!v || "Dieses Feld ist erforderlich."],
        },
    },
    mutations: {
        setEvent(state, event) {
          state.event = event;
        },
        setEventStarted(state, bool) {
          state.isEventStarted = bool;
        },
        SET_ALERT(state, data) {
            state.snackbar.message = data.msg;
            state.snackbar.type = data.type;
        },
        RESET_ALERT(state) {
            state.snackbar.message = '';
            state.snackbar.type = '';
        },
        setTimeLeft(state, timeLeft) {
            state.time_left = moment.duration(timeLeft, "HH:mm:ss").asSeconds();
        },
        setTimeLeftCountdown(state) {
            if(state.time_left && state.time_left > 0) {
                state.closeTime = state.time_left < 31;
                state.time_left -= 1;
            }
        },
        setClearInterval(state, bool) {
            state.isClearInterval = bool;
        },
        setTimerRunning(state, bool) {
            console.log("setTimerRunning " + bool);
            state.isTimerRunning = bool;
        }
    },
    getters: {
        event(state) {
            return state.event;
        },
        timeLeft(state) {
            return state.time_left
        },
        timeLeftFormatted(state) {
          return moment.utc(state.time_left * 1000).format("HH:mm:ss");
        },
        closeTime(state) {
            return state.closeTime;
        },
        rules(state) {
            return state.rules;
        },
        isEventStarted(state) {
            return state.isEventStarted;
        },
        isClearInterval(state) {
            return state.isClearInterval;
        },
        isTimerRunning(state) {
            return state.isTimerRunning
        }
    },
    actions: {
        async set_alert({ commit }, payload) {
            commit('SET_ALERT', payload)
            setTimeout(() => {
                commit('RESET_ALERT')
            }, 3000)
        },
        async time_left({ commit, getters }) {
            if(!getters.isTimerRunning) {
                commit("setTimerRunning", true);
                const x = setInterval(() => {
                    if (getters.isClearInterval) {
                        console.log("stop!")
                        clearInterval(x);
                        commit("setTimerRunning", false);
                        commit('setClearInterval', false);
                    } else {
                        if (moment.duration(getters.timeLeft).asSeconds() == null || moment.duration(getters.timeLeft).asSeconds() === 0) {
                            clearInterval(x);
                            commit("setTimerRunning", false);
                            commit('setClearInterval', false);
                        }
                        commit('setTimeLeftCountdown')
                    }
                }, 1000)
            }
        },
    },
    modules: {
        auth
    }
})
