<template>
  <v-container>
    <v-btn
      @click="$router.push({ name: 'AdminTrailsAll' })"
      outlined
      icon
      class="mb-2"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-row>
      <v-col sm="4" cols="12">
        <v-card>
          <v-img
            v-if="!!trail.rest.img"
            :src="trail.rest.img"
            :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
          ></v-img>
          <v-img
            v-else
            class="d-flex align-center"
            left="auto"
            right="auto"
            :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
            ><v-icon class="d-flex" x-large color="primary"
              >mdi-image-area</v-icon
            ></v-img
          >
          <v-card-text class="pa-1">
            <v-list dense class="d-flex flex-wrap justify-space-between">
              <v-list-item class="no-flex">
                <v-list-item-icon class="mr-0">
                  <v-icon color="primary">mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-left subtitle-1"
                    >{{ trail.rest.location }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="no-flex">
                <v-list-item-icon class="mr-0">
                  <v-icon color="primary">mdi-medal</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="!!trail.challenges" class="text-left subtitle-1"
                    >{{ trail.challenges.length }} Stk.
                  </v-list-item-title>
                  <v-list-item-title v-else class="text-left subtitle-1"
                    >0 Stk.
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="no-flex">
                <v-list-item-icon class="mr-0">
                  <v-icon color="primary">mdi-timer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-left subtitle-1"
                    >{{ trail.duration }}'</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="8" cols="12">
        <v-card-actions class="justify-space-around pt-3 flex-wrap px-0">
          <v-dialog max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="fruit"
                class="br-md ma-1"
                :small="$vuetify.breakpoint.xs"
                v-bind="attrs"
                :block="$vuetify.breakpoint.xs"
                v-on="on"
                >Trail löschen
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="py-2 fruit black--text">
                  ACHTUNG
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog.value = false" icon color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="py-4"
                  >MÖCHTEST DU WIRKLICH DIESEN TRAIL LÖSCHEN?
                  <br />
                  <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.
                  <div class="d-flex justify-space-around my-3">
                    <v-btn
                      color="info"
                      :small="$vuetify.breakpoint.xs"
                      class="ma-2"
                      @click="dialog.value = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      @click="deleteTrail()"
                      color="primary"
                      :small="$vuetify.breakpoint.xs"
                      class="ma-2"
                      >Löschen
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>

          <v-btn
            color="primary"
            class="br-md ma-1"
            :small="$vuetify.breakpoint.xs"
            :block="$vuetify.breakpoint.xs"
            @click="editTrail()"
          >
            Speichern</v-btn
          >
        </v-card-actions>
        <v-card>
          <v-card-text class="pa-1 pa-sm-5">
            <v-tabs
              v-model="$store.state.trailEditTab"
              centered
              class="flex-wrap"
              height="35px"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                href="#tab-1"
                class="grey lighten-4"
                active-class="font-weight-bold"
              >
                Trail
              </v-tab>

              <v-tab
                href="#tab-2"
                class="grey lighten-4"
                active-class="font-weight-bold"
                style="min-width: 130px"
              >
                Challenges
              </v-tab>
            </v-tabs>
            <v-divider class="my-3"></v-divider>
            <v-tabs-items v-model="$store.state.trailEditTab">
              <v-tab-item key="0" value="tab-1">
                <v-card flat>
                  <v-card-text>
                    <v-form ref="editTrail">
                      <div class="my-2">
                        <label class="acme">*Trail-Name</label>
                        <v-text-field
                          outlined
                          color="primary"
                          placeholder="Gib dem Trail einen Namen"
                          v-model="trail.trailName"
                          required
                          dense
                          :rules="rules.requiredRules"
                          hide-details
                          clearable
                        >
                        </v-text-field>
                      </div>
                      <div class="my-2">
                        <label class="acme">Trail-Ort</label>
                        <v-text-field
                          outlined
                          color="primary"
                          placeholder="Wo findet dieser Trail statt?"
                          v-model="trail.rest.location"
                          dense
                          hide-details
                          clearable
                        >
                        </v-text-field>
                      </div>
                      <div class="my-2">
                        <label class="acme">*Zur Verfügung stehende Zeit</label>
                        <v-select
                          outlined
                          style="max-width: 120px"
                          color="primary"
                          v-model="trail.duration"
                          placeholder="Minuten"
                          required
                          :items="[15, 30, 60, 90, 120, 150, 180, 210]"
                          dense
                          :rules="rules.requiredRules"
                          hide-details
                        >
                        </v-select>
                      </div>
                      <div class="my-2">
                        <label class="acme">Schwierigkeit</label>
                        <v-select
                          outlined
                          style="max-width: 120px"
                          color="primary"
                          v-model="trail.rest.difficulty"
                          :items="['einfach', 'mittel', 'schwer']"
                          dense
                          hide-details
                        >
                        </v-select>
                      </div>
                      <div class="my-2">
                        <label class="acme">Trail-Beschreibung</label>
                        <v-textarea
                          outlined
                          color="primary"
                          placeholder="Füge eine Beschreibung des Trails ein."
                          v-model="trail.rest.description"
                          rows="3"
                          hide-details
                          clearable
                        >
                        </v-textarea>
                      </div>
                      <div class="my-2">
                        <label class="acme">Trail-Kurzbeschreibung</label>
                        <v-textarea
                          outlined
                          color="primary"
                          maxlength="80"
                          hint="Maximal 50 Zeichen zulässig."
                          placeholder="Dieser Kurzbeschrieb dient als Vorschau. Die Zeichen sind begrenzt."
                          v-model="trail.rest.shortDescription"
                          rows="3"
                          hide-details
                          clearable
                        >
                        </v-textarea>
                      </div>
                      <div class="my-2">
                        <label class="acme">Trail-Startpunkt</label>
                        <v-textarea
                          outlined
                          color="primary"
                          placeholder="Beschreibe, wo dieser Trail beginnt."
                          v-model="trail.rest.startPoint"
                          rows="3"
                          hide-details
                          clearable
                        >
                        </v-textarea>
                      </div>
                      <div>
                        <label class="acme">Bild</label>
                        <v-file-input
                          @change="onFileChange"
                          accept="image/png,image/jpg"
                          outlined
                          placeholder="Füge ein Bild für diesen Trail ein oder ersetze das Bestehende."
                          dense
                          clearable
                        ></v-file-input>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="2" value="tab-2">
                <v-card flat>
                  <challenge-create-in-trail></challenge-create-in-trail>
                  <v-card-text
                    v-if="!!trail.challenges"
                    class="pa-1 my-2"
                    style="max-height: 60vh; overflow-y: auto"
                  >
                    <v-card
                      class="my-2"
                      v-for="(challenge, idx) in trail.challenges"
                      :key="'challenge_' + idx"
                      elevation="3"
                    >
                      <v-card-text v-if="!!challenge" class="pa-2">
                        <v-row class="justify-space-between align-center">
                          <v-col md="8" sm="7" cols="6">
                            <div class="acme">{{ challenge.rest.name }}</div>
                            <div>
                              <strong>Antworttyp: </strong>
                              <span class="ml-3">
                                {{ $answerType.getAnswerType(challenge.type) }}
                              </span>
                            </div>
                            <div>
                              <strong>Anz. Tipps: </strong>
                              <span class="ml-3">
                                {{ challenge.tipp.length }} Stk.
                              </span>
                            </div>
                          </v-col>
                          <v-col
                            md="4"
                            sm="5"
                            cols="6"
                            class="text-right pr-2 pl-0"
                          >
                            <v-btn
                              color="primary"
                              class="br-md mr-2"
                              :small="$vuetify.breakpoint.xs"
                              :to="{
                                name: 'ChallengeShow',
                                params: { id: challenge.id },
                              }"
                            >
                              Anzeigen
                            </v-btn>
                            <v-dialog max-width="300" v-model="dialog" :retain-focus="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="fruit"
                                  icon
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <template v-slot:default="dialog">
                                <v-card>
                                  <v-card-title class="py-2 fruit black--text">
                                    ACHTUNG
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      @click="dialog.value = false"
                                      icon
                                      color="white"
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                  </v-card-title>
                                  <v-card-text class="py-4"
                                    >MÖCHTEST DU WIRKLICH DIESE CHALLENGE VOM
                                    TRAIL ENTFERNEN?
                                    <br />
                                    <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG
                                    GEMACHT WERDEN UND WIRD OHNE ZU SPEICHERN AUSGEFÜHRT.
                                    <div
                                      class="d-flex justify-space-around my-3"
                                    >
                                      <v-btn
                                        color="info"
                                        :small="$vuetify.breakpoint.xs"
                                        class="ma-2"
                                        @click="dialog.value = false"
                                      >
                                        Abbrechen
                                      </v-btn>
                                      <v-btn
                                        color="primary"
                                        :small="$vuetify.breakpoint.xs"
                                        class="ma-2"
                                        @click="deleteChallengeFromTrail(idx)"
                                      >
                                        Entfernen
                                      </v-btn>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                  <v-card-text v-else>Noch keine Challenges zu diesem Trail hinzugefügt</v-card-text>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChallengeCreateInTrail from "../components/modal/challenge-create-in-trail";
export default {
  name: "TrailEdit",
  components: { ChallengeCreateInTrail },
  data() {
    return {
      dialog: false,
      rules: this.$store.getters.rules,
      trail: {
        rest: {
        },
      },
    };
    },

  methods: {
    onFileChange(e){
      var file = e;

      if (file){
        this.createImage(file); // just one file => TODO: multiple files
      } else { // else block needed because this function runs on every change => even when removing image
        delete this.trail.rest.img
      }
      
    },
    createImage(file){
      var reader = new FileReader();

      reader.onload = () => {
        this.trail.rest.img = reader.result
      };

      if (file){
        reader.readAsDataURL(file); // base64
      }

      reader.onerror = (e) => {
        console.error(e)
      }
    },
    async deleteChallengeFromTrail(idx){
      this.trail.challenges.splice(idx, 1);   
      var idsChlg = {
        idsChallenge: []
      }

      // for each challenge in trail, get ids and put them in an array
      Object.keys(this.trail.challenges).forEach((key) => {
        idsChlg.idsChallenge.push(this.trail.challenges[key].id);
      })

      const res = await this.$api.deleteChallengeFromTrail(this.$route.params.id, idsChlg);
      this.trail = res.data;
      this.dialog = false;
    },
    async deleteTrail() {
      await this.$api.deleteTrail(this.$route.params.id);
      this.$router.push({ name: "AdminTrailsAll" });
    },
    async editTrail() {
      if (this.$refs.editTrail.validate()) {
        await this.$api.editTrail(this.$route.params.id, this.trail);
        // in order to immediately fetch just updated trail
        const res = await this.$api.getTrail(this.$route.params.id)
        this.trail = res.data
      } else {
        alert("Bitte alle benötigten Felder ausfüllen.");
      }
    },
  },
  async created() {
    const res = await this.$api.getTrail(this.$route.params.id);
    this.trail = res.data;
  },
};
</script>

<style scoped>
</style>