<template>
  <v-card elevation="8" v-if="info" height="100%" class="br-md">
    <v-card-text style="height: 100%" class="pa-1">
      <v-row class="ma-0" style="height: 100%">
        <v-col sm="6" cols="12">
          <v-img
            v-if="info.rest.img"
            :src="info.rest.img"
            :height="$vuetify.breakpoint.xs ? '150px' : '100%'"
          ></v-img>
          <v-img
            v-else
            class="d-flex align-center"
            left="auto"
            right="auto"
            :height="$vuetify.breakpoint.xs ? '150px' : '100%'"
            ><v-icon class="d-flex"
            x-large 
            color="primary"
              >mdi-image-area</v-icon
            ></v-img
          >        
        </v-col>
        <v-col sm="6" cols="22">
          <div
            class="justify-end font-weight-bold acme mb-4 d-flex align-center"
            style="font-size: 20px"
          >
            <div class="col text-center pa-0">{{ info.eventName }}</div>
            <div class="float-right">
              <v-dialog max-width="300">
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="py-2 fruit black--text">
                      ACHTUNG
                      <v-spacer></v-spacer>
                      <v-btn @click="dialog.value = false" icon color="white">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="py-4"
                      >MÖCHTEST DU WIRKLICH DIESE GRUPPE LÖSCHEN?
                      <br />
                      <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.
                      <div class="d-flex justify-space-around my-3">
                        <v-btn
                          color="info"
                          :small="$vuetify.breakpoint.xs"
                          class="ma-2"
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          color="primary"
                          :small="$vuetify.breakpoint.xs"
                          class="ma-2"
                        >
                          Löschen
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-space-between">
            <div class="black--text col pa-0">Datum:</div>
            <div>{{ info.date }}</div>
          </div>
          <div class="d-flex flex-wrap justify-space-between">
            <div class="black--text col pa-0">Zeitfenster:</div>
            <div>{{ $date.formatTime(info.startTime) }}-{{ $date.formatTime(info.endTime) }} Uhr</div>
          </div>
          <div class="d-flex flex-wrap justify-space-between">
            <div class="black--text col pa-0">Anz. Gruppen:</div>
            <div>{{ info.players.length }} Stk.</div>
          </div>
          <div class="d-flex flex-wrap justify-space-between">
            <div class="black--text col pa-0">Kurzbeschreibung:</div>
            <div>{{ info.rest.shortDescription }}</div>
          </div>
          <div class="text-center">
            <v-btn
              color="primary"
              class="br-md text-capitalize mt-7"
              :small="$vuetify.breakpoint.xs"
              @click="
                $router.push({ name: 'EventEdit', params: { id: info.id } })
              "
            >
              Bearbeiten
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "event-card",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
</style>