<template>
  <v-container class="overflow-hidden pa-0 fill-height">
    <v-card class="mt-0 fill-height justify-center" rounded="0" >

      <div class="codetrail-date-circle" :style="'background-color: ' + colors.primary">
        <span>
          <span class="day">{{dateDay}}</span><br>
          <span class="month">{{dateMonth}}</span>
        </span>
      </div>

      <div class="fill-height overflow-y-auto" style="padding-bottom: 80px">
        <v-img
            v-if="!!event.trail.rest.img"
            :src="event.trail.rest.img"
            class="codetrail-event-image"
        ></v-img>
        <v-img
            v-else
            class="d-flex align-center codetrail-event-image"
            left="auto"
            right="auto"
        >
          <v-icon class="d-flex" x-large color="primary">mdi-image-area</v-icon>
        </v-img>

        <v-card-title class="event-title justify-center mb-0 pb-0 font-weight-bold">
          {{ event.trail.trailName }}
        </v-card-title>

        <div class="text-subtitle-1 text-center my-0">
          <v-icon color="primary">mdi-map-marker</v-icon> {{event.trail.rest.location}}
        </div>

        <div class="text-subtitle-2 text-center my-0 font-weight-light">
          {{ event.trail.rest.startPoint }}
        </div>

        <v-card-text>
          <div class="d-flex justify-center mb-4">
            <div class="codetrail-stats-wrapper float-left">
              <div>
                <span class="num" :style="'color: ' + colors.primary">
                  {{ event.trail.anzChal }}
                </span>
              </div>
              <div>Posten</div>
            </div>
            <div class="codetrail-stats-wrapper float-left">
              <div>
                <span class="num" :style="'color: ' + colors.primary">
                {{ event.trail.duration }}
                </span>
              </div>
              <div>Dauer</div>
            </div>
            <div class="codetrail-stats-wrapper float-left">
              <div>
                <span class="num" :style="'color: ' + colors.primary">
                  {{ event.trail.rest.difficulty }} <!-- Easy, Normal, Hard, Expert -->
                </span>
              </div>
              <div>Schwierigkeit</div>
            </div>
  <!--          <div class="wrapper float-left">-->
  <!--            <div>-->
  <!--              <span class="num" :style="'color: ' + colors.primary">-->
  <!--              {{ event.startTime.replace(":", ".") }}-->
  <!--              </span>-->
  <!--            </div>-->
  <!--            <div>Start</div>-->
  <!--          </div>-->
  <!--          <div class="wrapper float-left">-->
  <!--            <div>-->
  <!--              <span class="num" :style="'color: ' + colors.primary">-->
  <!--                {{ event.endTime.replace(":", ".") }}-->
  <!--              </span>-->
  <!--            </div>-->
  <!--            <div>Ende</div>-->
  <!--          </div>-->
            <div class="float-end"></div>
          </div>

          <div class="text-justify px-4">
            <p class="codetrail-event-desc">
              <b>Infos zum Event: </b> Begebt euch zum Startpunkt. Dort beginnt der Trail. Wenn ihr da seid, drückt den Start-Knopf und wählt euren Gruppenname. Alle Gruppen, die am CodeTrail teilnehmen, benötigen einen Namen. Dieser Name wird später in der Rangliste erscheinen.
            </p>
            <p class="codetrail-trail-desc">
              <b>Infos zum Trail: </b> {{event.trail.rest.description}}
            </p>
          </div>
        </v-card-text>

        <v-card-text class="codetrail-bottom-button-wrapper">
            <v-row class="challenge-buttons" dense>
              <v-col class="text-no-wrap d-none" cols="12">
                <div v-if="canStartEvent" class="mb-4">
                  <span v-if="!isPlayerAtStart">
                    Begebt euch zum Startpunkt. Dort beginnt der Trail. Wenn ihr da seid, drückt den Start-Knopf und wählt euren Gruppenname.
                  </span>
                  <span v-if="isPlayerAtStart">
                    Alle Gruppen, die am CodeTrail teilnehmen, benötigen einen Namen. Dieser Name wird später in der Rangliste erscheinen.
                  </span>
                </div>
              </v-col>

              <v-col cols="12">
                <div v-if="!hasEventStarted">
                  <v-btn color="secondary" dark v-if="isEventInFuture" class="w-100 pa-6">
                    Event nocht nicht gestartet
                  </v-btn>

                  <v-btn color="secondary" dark v-if="isEventExpired" class="w-100 pa-6">
                    Event abgelaufen
                  </v-btn>

                  <v-btn
                      @click="atStart"
                      color="primary"
                      dark
                      :disabled="!canStartEvent"
                      v-if="canStartEvent && !isPlayerAtStart && !isEventExpired && !isEventInFuture"
                      class="w-100 pa-6"
                  >
                    Trail starten
                  </v-btn>

                  <transition name="slide">
                    <v-text-field
                        v-if="isPlayerAtStart"
                        outlined
                        hide-details
                        label="Gruppen-Name"
                        color="primary"
                        v-model="group_name"
                    >

                      <template v-slot:append>
                        <v-btn
                            color="primary"
                            class="codetrail-start-trail-button ma-0"
                            @click="startTrail"
                            :disabled="!canStartEvent || !isValidGroupName"
                            v-if="!hasEventStarted"
                        >
                          Speichern
                        </v-btn>
                      </template>
                    </v-text-field>
                  </transition>
                </div>

                <v-btn
                    @click="continueTrail"
                    color="primary"
                    dark
                    v-else
                    width="100%"
                    class="event-action-button pa-6"
                >
                  Fortsetzen
                  <span :class="'pl-4 ' + closeTime ? 'red--text' : ''">({{timeLeftFormatted ? timeLeftFormatted : "00:00:00"}})</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from "vuex";
import moment from "moment";
import eventPlayerMixin from "@/mixins/eventPlayerMixin";
export default {
  name: 'PlayerHome',
  mixins:[eventPlayerMixin],
  data: function () {
    return {
      hasEventStarted: false,
      isPlayerAtStart: false,
      group_name: ""
    };
  },
  computed: {
    ...mapGetters([
      "timeLeft",
      "playerId",
      "closeTime",
      "event",
      "timeLeftFormatted"
    ]),

    colors() {
      return this.$vuetify.theme.themes.light;
    },

    date() {
      return moment(this.event.date, "DD/MM/YYYY");
    },

    dateMonth() {
      return this.date.format("MMM");
    },

    dateDay() {
      return this.date.format("DD");
    },

    startTime () {
      return moment(this.event.startTime, "HH:mm");
    },

    endTime() {
      return moment(this.event.endTime, "HH:mm");
    },

    currentDate() {
      return moment().format("D.MM.YYYY");
    },

    currentTime() {
      return moment();
    },

    isEventInFuture() {
      return this.date._i && this.currentTime.isBefore(this.startTime);
    },

    isEventExpired() {
      return this.date._i && this.currentTime.isAfter(this.endTime);
    },

    canStartEvent() {
      return this.date._i === this.currentDate && this.currentTime.isBetween(this.startTime, this.endTime);
    },

    isValidGroupName() {
      return this.group_name && this.group_name !== "" && this.group_name.length >= 3;
    }
  },
  async created() {
    // redirect to score page if trail has already been finished
    if(await this.isTrailFinished()) {
      await this.$router.push({name: "PlayerScore", params: { idPlayer: this.playerId }, }).then();
    }
  },
  async mounted() {
    // load event data and form start and end time
    let res = await this.$api.getEventInfo();
    if(res) {
      res.data.startTime = this.$date.formatTime(res.data.startTime);
      res.data.endTime = this.$date.formatTime(res.data.endTime);
      this.$store.commit("setEvent", res.data);
    }

    // if the trail has already been started, set the timer
    let checkEventStatusRes = await this.$api.checkEventStatus();
    if(checkEventStatusRes) {
      this.hasEventStarted = checkEventStatusRes.data;
      if (this.hasEventStarted) {
        this.$store.commit("setEventStarted", true);
        await this.setTimeLeft();
      }
    }
  },
  methods: {

    atStart() {
      this.isPlayerAtStart = true;
    },

    // start trail action
    async startTrail() {
      if(this.isValidGroupName) {
        // try to start the event. In case of errors call `onStarError`
        if(await this.$api.startTrail(this.group_name, this.onStartTrailError)) {
          this.$store.commit("setEventStarted", true);
          await this.setTimeLeft();
          this.$router.push({name: "PlayerTrail"}).then();
        }
      }
    },

    // Button to continue a prev started trail
    continueTrail: function () {
      this.$router.push({name: "PlayerTrail"});
    },

    // handles errors that occurs when starting a trail
    onStartTrailError(e) {
      switch (e) {
        case this.$api.ERROR_CODES.ERROR_EVENT_FINISHED:
          this.$store.commit("setTimeLeftGeneral", null);
          this.$store.commit("setClearInterval", true); // reset time_left
          this.$router.push({ name: "PlayerHome" });
          alert(this.$api.ERROR_CODES.ERROR_EVENT_FINISHED);
          break;
        case this.$api.ERROR_CODES.ERROR_EVENT_NOT_STARTED:
          this.$store.commit("setTimeLeftGeneral", null);
          this.$store.commit("setClearInterval", true); // reset time_left
          this.$router.push({ name: "PlayerHome" });
          alert(this.$api.ERROR_CODES.ERROR_EVENT_NOT_STARTED);
          break;
        case this.$api.ERROR_CODES.ERROR_EVENT_NOT_AVAILABLE:
          this.$store.commit("setTimeLeftGeneral", null);
          this.$store.commit("setClearInterval", true); // reset time_left
          this.$router.push({ name: "PlayerHome" });
          alert(this.$api.ERROR_CODES.ERROR_EVENT_NOT_AVAILABLE);
          break;
        default:
          console.log("Unknown error: " + e);
          break;
      }
    },

    // Used to prevent the double display of two times
    sleep(milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },

    // checks if current player is allowed to do the trail
    async checkPlayerTrailStatus() {
      return await this.$api.checkTrailStatus() === this.$api.ERROR_CODES.ERROR_TRAIL_NOT_FINISHED;
    },
  }
};
</script>

<style>
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
  top: 0;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>

<style scoped>
.codetrail-date-circle {
  text-shadow: 1px 1px 2px #ffffff40;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 0.8;
  color: #ffffffe3;
  text-align: center;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  z-index: 2;
  border-radius: 30px !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12) !important;
}
.codetrail-date-circle .day {
  font-size: 18px;
}
.codetrail-date-circle .month {
  font-size: 10px
}

.codetrail-start-trail-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 56px !important;
  padding: 0 20px;
}
</style>