<template>
  <v-container>
    <v-btn @click="$router.go(-1)" outlined icon class="mb-2">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-row class="my-1">
      <v-col sm="4" cols="12">
        <v-card>
          <v-img
            v-if="!!trail.rest.img"
            :src="trail.rest.img"
            :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
          ></v-img>
          <v-img
            v-else
            class="d-flex align-center"
            left="auto"
            right="auto"
            :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
            ><v-icon class="d-flex" x-large color="primary"
              >mdi-image-area</v-icon
            ></v-img
          >
          <v-card-text class="pa-1">
            <div class="acme ma-3">
              {{ trail.trailName }}
            </div>
            <v-list dense class="d-flex flex-wrap">
              <v-list-item class="no-flex">
                <v-list-item-icon class="mr-0">
                  <v-icon color="primary">mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="!!trail.rest.location"
                    class="text-left subtitle-1"
                    >{{ trail.rest.location }}
                  </v-list-item-title>
                  <v-list-item-title v-else class="text-left subtitle-1"
                    >-
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="no-flex">
                <v-list-item-icon class="mr-0">
                  <v-icon color="primary">mdi-medal</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="!!trail.challenges"
                    class="text-left subtitle-1"
                    >{{ trail.challenges.length }} Stk.
                  </v-list-item-title>
                  <v-list-item-title v-else class="text-left subtitle-1"
                    >0 Stk.
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="no-flex">
                <v-list-item-icon class="mr-0">
                  <v-icon color="primary">mdi-timer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-left subtitle-1"
                    >{{ trail.duration }}'</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <div class="d-flex justify-center my-3 flex-wrap">
          <v-dialog
            scrollable
            max-width="500"
            v-if="$store.getters.isEventAdmin"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                :small="$vuetify.breakpoint.xs"
                class="ma-2"
                block
                v-bind="attrs"
                v-on="on"
                >Zu Event hinzufügen
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title
                  class="px-1 px-sm-5 py-2 primary black--text text-uppercase text-sm-h6 text-subtitle-2"
                >
                  Trail zu Event hinzufügen
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog.value = false" icon color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-form style="max-height: 60vh; overflow-y: auto">
                  <v-card-text>
                    <div class="mb-4">
                      Zu welchem Event möchtest du den Trail hinzufügen?
                    </div>
                    <v-list>
                      <v-list-item-group
                        v-model="selectedEvent"
                        mandatory
                        color="info"
                        active-class="border"
                      >
                        <v-list-item
                          v-for="(item, idx) in events"
                          :key="'trail__' + idx"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content class="pa-1">
                              <v-card class="ma-0">
                                <v-card-text class="pa-2">
                                  <v-row
                                    class="justify-space-between align-center"
                                  >
                                    <v-col cols="12">
                                      <div class="d-flex">
                                        <div class="black--text col pa-0">
                                          Event-Name:
                                        </div>
                                        <div class="col pa-0">
                                          {{ item.eventName }}
                                        </div>
                                      </div>
                                      <div class="d-flex">
                                        <div class="col pa-0 black--text">
                                          Datum:
                                        </div>
                                        <div class="col pa-0">
                                          {{ item.date }}
                                        </div>
                                      </div>
                                      <div class="d-flex">
                                        <div class="col pa-0 black--text">
                                          Zeitfenster:
                                        </div>
                                        <div class="col pa-0">
                                          {{ $date.formatTime(item.startTime) }}-{{ $date.formatTime(item.endTime) }} Uhr
                                        </div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <div class="text-center">
                    <v-btn
                      color="primary"
                      class="br-md mt-3"
                      :small="$vuetify.breakpoint.xs"
                      @click="addTrailToEvent(); done = true;"
                      v-if="!done"
                    >
                      Hinzufügen
                    </v-btn>
                    <v-btn color="success" class="br-md mt-3" icon v-else>
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </v-col>
      <v-col sm="8" cols="12">
        <v-card>
          <v-card-text class="px-1">
            <v-tabs
              v-model="$store.state.trailShowTab"
              centered
              class="flex-wrap"
              height="35px"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                href="#tab-1"
                class="grey lighten-4"
                active-class="font-weight-bold"
              >
                Trail
              </v-tab>

              <v-tab
                href="#tab-2"
                class="grey lighten-4"
                active-class="font-weight-bold"
              >
                Challenges
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="$store.state.trailShowTab">
              <v-tab-item key="0" value="tab-1">
                <v-card class="ma-1">
                  <v-card-title>Beschreibung</v-card-title>
                  <v-card-text>
                    <div
                      v-if="!!trail.rest.description"
                      v-html="trail.rest.description"
                    ></div>
                    <div v-else v-html="'Keine Angaben'"></div>
                  </v-card-text>
                </v-card>
                <v-card class="ma-1">
                  <v-card-title>Startpunkt</v-card-title>
                  <v-card-text>
                    <div
                      v-if="!!trail.rest.startPoint"
                      v-html="trail.rest.startPoint"
                    ></div>
                    <div v-else v-html="'Keine Angaben'"></div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="1" value="tab-2">
                <v-card flat>
                  <v-row class="my-0">
                    <v-col
                      cols="12"
                      v-for="(challenge, idx) in trail.challenges"
                      :key="'challengee_' + idx"
                    >
                      <v-card class="ma-1" elevation="3">
                        <v-card-text class="pa-2">
                          <v-row class="justify-space-between align-center">
                            <v-col md="8" sm="7" cols="6">
                              <div class="acme">{{ challenge.rest.name }}</div>
                              <div>
                                <strong>Antworttyp:</strong>
                                <span class="ml-3">
                                  {{
                                    $answerType.getAnswerType(challenge.type)
                                  }}
                                </span>
                              </div>
                              <div>
                                <strong>Anz. Tipps:</strong>
                                <span v-if="!!challenge.tipp" class="ml-3">
                                  {{ challenge.tipp.length }} Stk.
                                </span>
                                <span v-else class="ml-3">0 Stk.</span>
                              </div>
                            </v-col>

                            <v-col
                              md="4"
                              sm="5"
                              cols="6"
                              class="text-right pr-1 pl-0"
                            >
                              <v-btn
                                color="primary"
                                class="br-md mr-2"
                                :small="$vuetify.breakpoint.xs"
                                :to="{
                                  name: 'ChallengeShow',
                                  params: { id: challenge.id },
                                }"
                              >
                                Anzeigen
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      done: false,
      tab: 0,
      trail: {
        rest: {},
      },
      events: [],
      selectedEvent: null,
    };
  },
  methods: {
    async addTrailToEvent() {
      var idEvent = this.events[this.selectedEvent].id;
      await this.$api.addTrailToEvent(idEvent, this.trail.id); // TODO: handle errors

      this.dialog = false;
      this.getEvents();
    },
    async getEvents() {
      const res = await this.$api.getEvents();
      this.events = res.data;
    },
  },
  async created() {
    const res = await this.$api.getTrail(this.$route.params.id);
    this.trail = res.data;
    this.getEvents();
  },
};
</script>