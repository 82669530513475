<template>
    <div class="my-4">
        <div class="text-h3 text-center">CodeTrail</div>
        <router-view></router-view>
        <div class="text-center">
            <v-btn :to="{ name: 'About' }" rounded
                   outlined
                   color="primary"
                   :small="$vuetify.breakpoint.xs"
            >Über Codetrail</v-btn>
        </div>
        <v-snackbar
      :timeout="2500"
      :value="!!snackbar.message"
      right
      top
      :color="snackbar.type"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="$store.commit('RESET_ALERT')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: "authLayout",
        computed: {
            ...mapState(['snackbar'])
        }
    }
</script>

<style scoped>

</style>