import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters([
            "timeLeft",
            "isEventStarted"
        ]),
    },
    watch: {
        timeLeft(timeLeft) {
            if(timeLeft === 0 && this.isEventStarted) {
                this.gotoScore().then();
            }
        }
    },
    methods: {

        // This method is called on start trail or on returning to a previously started trail.
        // It ensures that the time left is correctly set.
        async setTimeLeft() {
            let timeLeftRes = await this.$api.getTimeLeft();
            if(timeLeftRes) {
                let timeLeft = this.$date.formatTime(timeLeftRes.data);
                console.log(timeLeft); // todo if 0 > time left gotoscore
                this.$store.commit("setTimeLeft", timeLeft);
                this.$store.commit("setClearInterval", false);
                this.$store.dispatch("time_left").then();
            }
        },

        clearTimeLeftInterval() {
            this.$store.commit("setClearInterval", true); // reset time_left
        },

        // checks if current player is allowed to do the trail
        async isTrailNotFinished() {
            return await this.$api.checkTrailStatus() === this.$api.ERROR_CODES.ERROR_TRAIL_NOT_FINISHED;
        },

        // checks if current player is allowed to do the trail
        async isTrailFinished() {
            return await this.$api.checkTrailStatus() !== this.$api.ERROR_CODES.ERROR_TRAIL_NOT_FINISHED;
        },

        // jump to score page
        async gotoScore() {
            await this.$api.endTrail();
            this.clearTimeLeftInterval();
            this.$router.push({name: "PlayerScore", params: { idPlayer: this.playerId }}).then();
        },
    }
}