import Api from "../plugins/baseApi";

export default {
    /* ERROR CODES OR WARNINGS COMING FROM BACKEND */
    // NOTE: there are more responses, but I picked these

    /* TODO: LOGIN ERRORS */

    /* PLAYER ERRORS */
    ERROR_CODES: {
        ERROR_CHALLENGE_ALREADY_EXISTS: "Challenge bereits vorhanden",
        ERROR_CHALLENGE_NOT_STARTED: "Challenge noch nicht gestartet",
        ERROR_CHALLENGE_NOT_STARTED_YET: "Challenge wurde noch nicht gestartet", // for player() request => see below
        ERROR_CHALLENGE_NOT_SOLVED_YET: "Diese Challenge wurde noch nicht gelöst",
        ERROR_CHALLENGE_HAS_NO_TIPS: "Challenge has no Tipps",
        ERROR_CHALLENGE_INVALID_TIP_NUMBER: "Ungültige Tipp Nummer",

        ERROR_EVENT_NOT_STARTED: "Event hat noch nicht begonnen",
        ERROR_EVENT_FINISHED: "Event ist schon zu Ende",
        ERROR_EVENT_NOT_AVAILABLE: "Event ist nicht heute!",
        ERROR_EVENT_NOT_AVAILABLE2: "Event ist nicht heute",

        ERROR_PLAYER_NOT_EXISTS: "Player existiert nicht",

        ERROR_TRAIL_NOT_FINISHED: "Nicht fertig",

        WARNING_ALL_CHALLENGES_SOLVED: "Alle Challenges wurden bereits gelöst",
        WARNING_EVENT_ALREADY_STARTED: "Event bereits gestartet",
    },

    /* TODO: TRAILADMIN ERRORS */
    /* TODO: EVENTADMIN ERRORS */

    /* LOGIN */
    async signIn(User) {
        var res;
        await Api()
            .post('/api/auth/signin', User)
            .then((response) => {
                res = response.data;
                if(res) {
                    localStorage.setItem('profile', JSON.stringify(res));
                }
            }).catch((error) => {
                localStorage.removeItem('access-token')
                localStorage.removeItem('player-id')
                localStorage.removeItem('profile');
                throw error;
            })
        return res;
    },

    /* ROLE TRAILADMIN */
    async getChallenges() {
        var res;
        await Api()
            .get("admin/all")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async postChallenge(chlg_obj) {
        var res;
        await Api()
            .post("/admin/challenge", chlg_obj)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async postTipsToChallenge(idChlg, tips) {
        await Api()
            .post(`/admin/challenge/${idChlg}/tipp`, tips)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async editChallenge(idChlg, chlg_obj) {
        await Api()
            .post(`/admin/challenge/${idChlg}`, chlg_obj)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async getChallenge(idChlg) {
        var res;
        await Api()
            .get(`/admin/challenge/${idChlg}`)
            .then((response) => {
                res = response;
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async deleteChallenge(idChlg) {
        await Api()
            .delete(`/admin/challenge/${idChlg}`)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async postTrail(trail_obj) {
        await Api()
            .post("/admin/trail", trail_obj)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async editTrail(idTrail, trail_obj) {
        await Api()
            .post(`/admin/trail/${idTrail}`, trail_obj)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async getTrail(idTrail) {
        var res;
        await Api()
            .get(`/admin/trail/${idTrail}`)
            .then((response) => {
                res = response;
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async getTrails() {
        var res;
        await Api()
            .get("admin/trails/all")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async deleteTrail(idTrail) {
        await Api()
            .delete(`/admin/trails/${idTrail}`)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async addChallengesToTrail(idTrail, idsChlg) { // this request is not used for this purpose but for deleting challenges from trail (see deleteChallengeFromTrail)
        var res;
        await Api()
            .post(`/admin/addChallenges/${idTrail}`, idsChlg)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async addChallengeToTrail(idTrail, idChlg) {
        var res;
        await Api()
            .post(`/admin/addChallenge/${idTrail}`, idChlg)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                if (e.response.data === this.ERROR_CODES.ERROR_CHALLENGE_ALREADY_EXISTS) {
                    res = e.response.data;
                } else {
                    console.error(e);
                }
            });
        return res
    },
    // this function just overrides the actual array of challenges in a trail
    async deleteChallengeFromTrail(idTrail, idsChlg) { // can be one or more challenge ids or none (to delete all challenges from a trail)
        var res;
        await Api()
            .post(`/admin/addChallenges/${idTrail}`, idsChlg)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },

    /* ROLE EVENTADMIN */
    async postEvent(event_obj) {
        var res;
        await Api()
            .post("/admin/event", event_obj)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async deleteEvent(idEvent) {
        await Api()
            .delete(`/admin/event/${idEvent}`)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async editEvent(idEvent, event_obj) {
        await Api()
            .post(`/admin/event/${idEvent}`, event_obj)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async addTrailToEvent(idEvent, idTrail) {
        var res;
        await Api()
            .post(`/admin/addTrail/${idEvent}/${idTrail}`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                // TODO: handle basic error codes from backend
                /* if(e.response.data === this.ERROR_CODES.ERROR_CHALLENGE_ALREADY_EXISTS){
                    res = e.response.data;
                } else {
                    console.error(e);
                } */
                console.error(e);
            });
        return res
    },
    async getEvent(idEvent) {
        var res;
        await Api()
            .get(`/admin/event/${idEvent}`)
            .then((response) => {
                res = response;
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async getEvents() {
        var res;
        await Api()
            .get("admin/events/all")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async postGroups(idEvent, amountOfGroups) {
        var res;
        await Api()
            .post(`/admin/event/createPlayers/${idEvent}/${amountOfGroups}`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },
    async deleteGroupsByEvent(idEvent) {
        await Api()
            .delete(`/admin/event/${idEvent}/player`)
            .then(() => {
            })
            .catch((e) => {
                console.error(e);
            });
    },
    async deleteTrailFromEvent(idEvent) {
        var res;
        await Api()
            .delete(`/admin/event/${idEvent}/trail`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e);
            });
        return res
    },

    /* PLAYER => ROLE: USER */
    async getEventInfo() {
        var res;
        await Api()
            .get("/players/infos")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_PLAYER_NOT_EXISTS:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res
    },
    async checkEventStatus() {
        var res;
        await Api()
            .get("/players/started")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                console.error(e)
            });
        return res
    },
    async checkTrailStatus() { // this request shows whether trail is finished or not. If it's finished, player will be the response, which can be used in PlayerScore
        var res;
        await Api()
            .get("/players/finished")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_TRAIL_NOT_FINISHED:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res
    },

    // Start trail request with group name
    async startTrail(groupName, onError) {
        let res;
        await Api().put("/players/start", { groupName: groupName })
            .then(_res => {
                res = _res;
            })
            .catch(e => onError(e.response.data));
        return res;
    },

    // Returns the current trail challenge for current user
    async getCurrentChallenge(onLoadChallengeError = null) {
        var res;
        await Api()
            .get("/players/player")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                if(onLoadChallengeError) {
                    onLoadChallengeError(e.response.data);
                    res = false;
                    return;
                }
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_PLAYER_NOT_EXISTS:
                        res = e.response.data;
                        break;
                    case this.ERROR_CODES.ERROR_CHALLENGE_NOT_STARTED_YET:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res
    },

    // Returns a challenge by its id
    async getChallengeById(challengeId) { // in order to be able to jump back or forth => TODO: not implemented yet
        var res;
        await Api()
            .get(`/players/getChallenge/${challengeId}`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_CHALLENGE_NOT_SOLVED_YET:
                        res = e.response.data;
                        break;
                    case this.ERROR_CODES.ERROR_CHALLENGE_NOT_STARTED:
                        res = e.response.data;
                        break;
                    case this.ERROR_CODES.ERROR_PLAYER_NOT_EXISTS:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res
    },

    // Get all challenges from current trail
    async getCurrentChallenges() {
        let res;
        await Api()
            .get(`/players/challenges`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_PLAYER_NOT_EXISTS:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res;
    },

    async postSolution(sol_obj) { // response is next challenge
        var res;
        await Api()
            .post("/players/solution", sol_obj)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.WARNING_ALL_CHALLENGES_SOLVED:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res
    },
    async useTip(number) {
        var res;
        await Api()
            .put(`/players/useTipp/${number}`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_CHALLENGE_HAS_NO_TIPS:
                        res = 'Challenge hat keine Tipps';
                        break;
                    case this.ERROR_CODES.ERROR_CHALLENGE_INVALID_TIP_NUMBER:
                        res = 'Ungültige Tip-Numer';
                        break;
                }
                console.error(e)
            });
        return res
    },
    async getTips(number) {
        var res;
        await Api()
            .get(`/players/getTipps/${number}`)
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_CHALLENGE_HAS_NO_TIPS:
                        res = 'Challenge hat keine Tipps';
                        break;
                }
                console.error(e);
            });
        return res;
    },
    async getTimeLeft() {
        var res;
        await Api()
            .get("/players/time")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                switch (e.response.data) {
                    case this.ERROR_CODES.ERROR_EVENT_NOT_AVAILABLE2:
                        res = e.response.data;
                        break;
                }
                console.error(e)
            });
        return res
    },
    async getRanking() {
        var res;
        await Api()
            .get("/players/rangliste")
            .then((response) => {
                res = response
            })
            .catch((e) => {
                // todo error codes
                console.error(e)
            });
        return res
    },

    async endTrail() {
        var res;
        await Api().put("players/end")
            .then(_res => {
                if(_res) {
                    res = _res;
                }
            })
            .catch(err => {
                console.log(err);
            });

        return res;
    }
};
