<template>
    <div>
        <v-app-bar dark fixed app height="64px" :clipped-left="true">
            <v-app-bar-nav-icon @click.stop="drawer =!drawer" class="position-absolute"></v-app-bar-nav-icon>
            <div class="text-center text-h5 mx-auto">CodeTrail</div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" dark app fixed :clipped="true">
            <v-list dense class="pa-2">
                <v-list-item :to="{name: homeName, params: { idPlayer: playerId }}"
                             v-if="homeName === 'PlayerHome'" active-class="white primary--text">
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            Home
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{name: homeName}" active-class="white--text"
                             v-else>
                    <v-list-item-content>
                        <v-list-item-title class="title"
                        >
                            Home
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block @click="logOut" class="text-capitalize">
                        {{isPlayer?'Event verlassen':'Logout'}}
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-main>
            <router-view></router-view>
        </v-main>
        <v-snackbar
      :timeout="2500"
      :value="!!snackbar.message"
      right
      top
      :color="snackbar.type"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="$store.commit('RESET_ALERT')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>

<script>
    import {mapState,mapGetters} from 'vuex'

    export default {
        name: "dashboardLayout",
        data() {
            return {
                drawer: false,
                homeName: ''
            }
        },
        computed: {
            ...mapGetters(['playerId','isPlayer']),
            ...mapState(['snackbar'])
        },
        mounted: function () {
            // if route is 'NotFound', then no logout button is needed in Sidebar
            if (this.$route.name === 'NotFound') {
                this.disable_logout = true
            }
            if (this.isPlayer) {
                this.homeName = "PlayerHome";
            } else if (this.$store.getters.isTrailAdmin) {
                this.homeName = "AdminTrailsAll";
            } else if (this.$store.getters.isEventAdmin) {
                this.homeName = "Events";
            }
        },
        methods: {
            async logOut() {
                await this.$store.dispatch('LogOut');
                this.$router.push('/');
            },
        }
    }
</script>
