<template>
  <v-card elevation="8" v-if="info" height="100%" class="br-md">
    <v-card-text style="height: 100%" class="pa-1">
      <v-row class="ma-0" style="height: 100%">
        <v-col sm="6" cols="12">
          <v-img
            v-if="info.rest.img"
            :src="info.rest.img"
            :height="$vuetify.breakpoint.xs ? '150px' : '100%'"
          ></v-img>
          <v-img
            v-else
            class="d-flex align-center"
            left="auto"
            right="auto"
            :height="$vuetify.breakpoint.xs ? '150px' : '100%'"
            ><v-icon class="d-flex" x-large color="primary"
              >mdi-image-area</v-icon
            ></v-img
          >
        </v-col>
        <v-col sm="6" cols="12">
          <div
            class="text-center font-weight-bold acme mb-4"
            style="font-size: 20px"
          >
            {{ info.rest.name }}
          </div>
          <div class="d-flex flex-wrap justify-space-between">
            <div class="black--text col pa-0">Antworttyp:</div>
            <div>{{ $answerType.getAnswerType(info.type) }}</div>
          </div>
          <div class="d-flex flex-wrap justify-space-between">
            <div class="black--text col pa-0">Anz. Tipps:</div>
            <div v-if="!!info.tipp">{{ info.tipp.length }} Stk.</div>
            <div v-else>0 Stk.</div>
          </div>
          <div class="text-center">
            <v-btn
              color="primary"
              class="br-md text-capitalize mt-7"
              :small="$vuetify.breakpoint.xs"
              @click="
                $router.push({ name: 'ChallengeEdit', params: { id: info.id } })
              "
            >
              Bearbeiten</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "challenge-card",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  created(){
    console.log(this.info)
  }
};
</script>

<style scoped>
</style>