<template>
  <v-dialog scrollable max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        disabled
        class="font-weight-bold"
        v-bind="attrs"
        v-on="on"
        :small="$vuetify.breakpoint.xs"
      >
        <v-icon left color="primary" :large="!$vuetify.breakpoint.xs">
          mdi-plus-circle-outline
        </v-icon>
        <span class="ml-2">Challenge erstellen und einfügen</span>
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title
          class="px-1 px-sm-5 py-2 primary black--text text-uppercase text-sm-h6 text-subtitle-2"
        >
          Challenge erstellen
          <v-spacer></v-spacer>
          <v-btn @click="dialog.value = false" icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="createChallengeModal">
          <v-card-text
            style="max-height: 55vh; overflow-y: auto; overflow-x: hidden"
          >
            <div class="my-2">
              <label class="acme">*Challenge-Name</label>
              <v-text-field
                outlined
                color="primary"
                placeholder="Gib der Challenge einen Namen."
                v-model="challenge.name"
                required
                dense
                :rules="rules.requiredRules"
                hide-details
                clearable
              >
              </v-text-field>
            </div>
            <div class="my-2">
              <label class="acme">*Challenge-Beschreibung</label>
              <v-textarea
                outlined
                color="primary"
                placeholder="Hier kommt die Beschreibung und die Frage hin."
                v-model="challenge.frage"
                rows="3"
                hide-details
                clearable
                required
                :rules="rules.requiredRules"
              >
              </v-textarea>
            </div>
            <div class="my-2">
              <label class="acme">*Antworttyp</label>
              <v-radio-group
                class="mt-0"
                v-model="challenge.type"
                @change="
                  uncheckAll();
                  challenge.type = $event;
                "
                row
                dense
                required
                hide-details
                :rules="rules.requiredRules"
              >
                <v-radio label="Text" value="text"></v-radio>
                <v-radio label="Number" value="number"></v-radio>
                <v-radio label="Single Choice" value="singleChoice"></v-radio>
                <v-radio label="Multiple Choice" value="multipleChoice"></v-radio>
                <v-radio label="Wegbeschreibung" value="transfer"></v-radio>
                <v-radio label="Informationen" value="info"></v-radio>
              </v-radio-group>
            </div>
            <div class="my-2" v-if="challenge.type === 'text'">
              <label class="acme">*Lösung:</label>
              <v-text-field
                v-model="challenge.solution"
                outlined
                dense
                placeholder="Text als Lösung"
                type="text"
                hide-details
                required
                :rules="rules.requiredRules"
                clearable
              >
              </v-text-field>
            </div>
            <div class="my-2" v-else-if="challenge.type === 'number'">
              <label class="acme">*Lösung:</label>
              <v-text-field
                v-model="challenge.solution"
                outlined
                dense
                placeholder="Nummer als Lösung"
                type="number"
                hide-details
                required
                :rules="rules.requiredRules"
                clearable
              >
              </v-text-field>
            </div>
            <div class="my-2" v-else-if="challenge.type !== 'info' && challenge.type !== 'transfer'">
              <label class="acme">*Antwortmöglichkeiten:</label>
              <div
                v-for="(choice, idx) in challenge.possibleSolutions"
                :key="'choice' + idx"
                class="d-flex flex-column"
              >
                <div class="mt-3 d-flex">
                  <ckeditor
                    type="classic"
                    v-model="choice.value"
                    :config="editorConfig"
                  ></ckeditor>
                  <v-btn color="fruit" small @click="deleteChoice(idx)" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-row">
                  <v-icon
                    v-if="disabled == true"
                    @click="iconClicked()"
                    color="fruit"
                    class="ml-2"
                    >mdi-close</v-icon
                  >
                  <v-checkbox
                    v-if="challenge.type === 'singleChoice'"
                    @change="isSingleChoice()"
                    class="my-1"
                    label="Ist korrekt"
                    :value="idx"
                    v-model="challenge.solution"
                    :disabled="disabled"
                    hide-details
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-else
                    class="my-1"
                    label="Ist korrekt"
                    :value="idx"
                    v-model="challenge.solution"
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
              <div class="text-right my-3">
                <v-btn
                  :small="$vuetify.breakpoint.xs"
                  color="primary"
                  @click="addChoice"
                  >Antwort hinzufügen
                </v-btn>
              </div>
            </div>
            <div class="my-2">
              <label class="acme">Bild</label>
              <v-file-input
                @change="onFileChange"
                accept="image/png,image/jpg"
                outlined
                dense
                placeholder="Füge ein Bild für diese Challenge ein."
                clearable
              ></v-file-input>
            </div>
            <div>
              <div class="acme text-h6">Tipps</div>
              <v-card
                elevation="3"
                v-for="(tip, idx) in tips"
                :key="'tip_' + idx"
              >
                <v-card-title
                  class="my-0 pt-2 pb-1 text-subtitle-1 font-weight-bold"
                >
                  Tipp {{ idx + 1 }}
                  <v-btn color="fruit" small @click="deleteTip(idx)" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="my-1">
                  <v-row class="my-0" align="center">
                    <v-text-field
                      class="mx-2 my-1"
                      label="Titel"
                      rows="3"
                      maxlength="15"
                      counter
                      hint="Der Titel sollte nicht zu viel verraten."
                      v-model="tips[idx].title"
                      outlined
                      dense
                      type="text"
                      required
                      :rules="rules.requiredRules"
                      clearable
                    ></v-text-field>
                    <v-textarea
                      class="mx-2 my-1"
                      label="Text"
                      rows="3"
                      v-model="tips[idx].text"
                      outlined
                      dense
                      type="text"
                      hide-details
                      required
                      :rules="rules.requiredRules"
                      clearable
                    ></v-textarea>
                  </v-row>
                </v-card-text>
              </v-card>
              <div class="my-2">
                <v-btn
                  text
                  class="font-weight-bold"
                  :small="$vuetify.breakpoint.xs"
                  @click="addTip"
                >
                  <v-icon left color="primary" :large="!$vuetify.breakpoint.xs">
                    mdi-plus-circle-outline
                  </v-icon>
                  <span class="ml-2">Tipp hinzufügen</span>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="text-center">
            <div>
              Angaben ohne Stern können auch im Bearbeitungsmodus erstellt
              werden.
            </div>
            <v-btn
              color="primary"
              @click="
                checkInput();
                dialog.value = false;
              "
              :small="$vuetify.breakpoint.xs"
              class="br-md mt-3"
              >Erstellen
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "challenge-create-in-trail",
  data() {
    return {
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true,
        },
      },
      rules: this.$store.getters.rules,
      disabled: false,
      challenge: {
        type: "text",
        solution: [],
        possibleSolutions: [{ value: "" }, { value: "" }],
      },
      tips: [],
    };
  },
  methods: {
    uncheckAll() {
      this.disabled = false;
      this.challenge.solution = [];
    },
    isSingleChoice() {
      this.disabled = this.challenge.solution.length > 0 ? true : false;
    },
    iconClicked() {
      this.disabled = !this.disabled;
      this.challenge.solution = [];
    },
    deleteChoice(idx) {
      if (this.challenge.possibleSolutions.length > 2) {
        this.challenge.possibleSolutions.splice(idx, 1);
      } else {
        this.$store.dispatch("set_alert", {
          msg: "Min. 2 mögliche Antworten",
          type: "error",
        });
      }
    },
    addChoice() {
      if (this.challenge.possibleSolutions.length < 4) {
        this.challenge.possibleSolutions.push({ value: "" });
      } else {
        this.$store.dispatch("set_alert", {
          msg: "Max. 4 mögliche Antworten",
          type: "error",
        });
      }
    },
    addTip() {
      this.tips.push({
        title: "",
        text: "",
      });
    },
    deleteTip(idx) {
      this.tips.splice(idx, 1);
    },
    onFileChange(e) {
      // TODO: reduce code duplication (same function in AdminTrailsAll.vue)
      var file = e;

      if (file) {
        this.createImage(file); // just one file => TODO: multiple files
      } else {
        // else block needed because this function runs on every change => even when removing image
        delete this.challenge.img;
      }
    },
    createImage(file) {
      // TODO: reduce code duplication (same function in AdminTrailsAll.vue)
      var reader = new FileReader();

      reader.onload = (e) => {
        this.challenge.img = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.onerror = (e) => {
        console.error(e);
      };
    },
    async postChallenge() {
      if (this.$refs.createChallengeModal.validate()) {
        if (
          this.challenge.type == "singleChoice" ||
          this.challenge.type == "multipleChoice"
        ) {
          // only stringify if solution is of type array => which singleChoice & multipleChoice are
          this.challenge.solution = JSON.stringify(this.challenge.solution); // necessary because backend can only process strings, not array
        }
        var res = await this.$api.postChallenge(this.challenge);
        if (this.tips.length > 0) {
          await this.$api.postTipsToChallenge(res.data.id, this.tips);
          this.tips = [];
        }
        // in order to immediately fetch just added challenge
        this.$emit("onChallengePost"); // on this event, call getChallenge() in parent component
      } else {
        alert("Bitte alle benötigten Felder ausfüllen.");
      }
    },
    checkInput() {
      var isSingleChoice = this.challenge.type === "singleChoice";
      var isMultipleChoice = this.challenge.type === "multipleChoice";
      if (isSingleChoice || isMultipleChoice) {
        // cannot check directly like other fields, therefore checking here
        if (this.challenge.solution.length != 0) {
          this.postChallenge();
        } else {
          alert("Bitte eine Lösung auswählen");
        }
      } else {
        this.postChallenge();
      }
    },
  },
};
</script>

<style scoped>
</style>