<template>
  <v-container class="player-login overflow-hidden pa-0 h-100">

    <v-card class="justify-center fill-height mt-0" rounded="0">

      <div class="codetrail-welcome-message position-absolute">
        <h1>Willkommen <br>beim CodeTrail</h1>
      </div>

      <div class="codetrail-about-circle position-absolute" :style="'background-color: ' + colors.primary">
            <span class="pb-1" @click="$router.push('/about')">
              <small>
                <span class="font-weight-light">Über</span><br/>
                <span class="font-weight-medium"><small>CodeTrail</small></span>
              </small>
            </span>
      </div>

      <v-img src="../assets/zhaw-volkart.jpg" height="calc(100% - 240px)"/>

      <v-card-text style="height: 240px">
        <v-form ref="form" v-model="form.valid" @submit.prevent="loginPlayer">
          <v-container grid-list-md text-xs-center class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <p class="my-2 text-justify">
                  <span v-if="showError" :style="'color: ' + colors.error">{{errorMessage}}</span>
                  <span v-else>Gib deine Event-Zugansdaten ein. Bei Fragen melde dich beim Event-Organisator.</span>
                </p>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    dense
                    outlined
                    hide-details
                    label="Event-Name"
                    color="primary"
                    v-model="form.fields.username"
                    required
                    :rules="form.rules.username"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                    dense
                    outlined
                    hide-details
                    label="Gruppen-Id"
                    color="primary"
                    type="text"
                    v-model="form.fields.groupNum"
                    required
                    :rules="form.rules.groupNum"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                    dense
                    outlined
                    hide-details
                    label="PIN"
                    color="primary"
                    v-model="form.fields.password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                    required
                    :rules="form.rules.password"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-btn
                    type="submit"
                    color="primary"
                    class="ma-0 w-100"
                    height="40px"
                >
                  Zum Trail
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'PlayerLogin',
  data: function () {
    return {
      role: 'Player',
      form: {
        fields: {
          username: null,
          groupNum: null,
          password: null
        },
        valid: false,
        rules: {
          username: [this.requiredRule()],
          groupNum: [this.requiredRule()],
          password: [this.requiredRule()]
        }
      },
      show1: false,
      showError: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters([
      "rules",
      "playerId"
    ]),

    colors() {
      return this.$vuetify.theme.themes.light;
    }

  },
  methods: {

    requiredRule(message = 'Dieses Feld muss gefüllt sein') {
      return (value) => (!!value || value === 0) || message;
    },

    async loginPlayer() {
      if (this.$refs.form.validate()) {
        // create user with given input
        const User = {
          username: this.form.fields.username + "-" + this.form.fields.groupNum,
          password: this.form.fields.password
        };

        // set timer running to false
        this.$store.commit("setTimerRunning", false);

        // try to login player
        try {
          await this.$store.dispatch('LogIn', User);
          if (this.$store.getters.isPlayer && this.role === 'Player') {
            this.$router.push({name: 'PlayerHome', params: { playerId: this.playerId }}).then();
          }
        } catch (e) {
          this.errorMessage = 'Etwas stimmt nicht. Bitte überprüfe deine Angaben oder melde dich beim Event-Organisator' + e;
          this.showError = true;
        }
      }
    }
  }
};
</script>

<style scoped>
.codetrail-welcome-message {
  left: 20px;
  top: 30px;
  z-index: 2;
}
.codetrail-welcome-message h1 {
  line-height: 1.1;
  font-weight: 100;
  font-size: 36px;
  text-shadow: 1px 1px 2px #ffffff40;
}
.codetrail-about-circle {
  text-shadow: 1px 1px 2px #ffffff40;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 0.9;
  color: #ffffffe3;
  text-align:center;
  position: absolute;
  right: 20px;
  top: calc(100% - 340px);
  height: 60px;
  width: 60px;
  z-index: 2;
  border-radius: 30px;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12) !important
}
</style>
