<template>
    <div>
        <v-container>
            <v-card class="my-7 mx-auto" max-width="600" elevation="6">
                <v-row class="ma-0">
                    <v-col cols="12" class="px-7 py-3">
                        <div class="ma-auto text-center primary--text">
                            <div class="subtitle my-3">
                                <v-btn-toggle
                                        mandatory
                                        dense
                                        v-model="role"
                                        class="flex-wrap"
                                        active-class="white primary--text font-weight-bold"
                                >
                                    <v-btn value="Player" class="text-capitalize" dark>
                                        Player
                                    </v-btn>

                                    <v-btn value="TrailAdmin" class="text-capitalize" dark>
                                        TrailAdmin
                                    </v-btn>

                                    <v-btn value="EventAdmin" class="text-capitalize" dark>
                                        EventAdmin
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                            <v-icon class="primary--text darken-1" x-large>mdi-account-circle</v-icon>
                        </div>
                        <v-card class="ma-auto" elevation="0" width="85%">
                            <v-form ref="form" class="text-center">
                                <div class="headline secondary--text font-weight-bold my-2">{{logInfo.title}}</div>
                                <p class="mb-7">{{logInfo.description}}</p>
                                <v-text-field v-model="input.username"
                                              :label="logInfo.placeholder1"
                                              required
                                              autocomplete="off"
                                              :rules="rules.requiredRules"
                                >
                                </v-text-field>
                                <v-text-field v-model="input.groupNum"
                                              v-if="role==='Player'"
                                              :label="logInfo.placeholder3"
                                              type="number"
                                              required
                                              autocomplete="off"
                                              :rules="rules.requiredRules"
                                >
                                </v-text-field>
                                <v-text-field v-model="input.password"
                                              :label="logInfo.placeholder2"
                                              :type="show1 ? 'text' : 'password'"
                                              required
                                              :rules="rules.requiredRules"
                                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                              @click:append="show1 = !show1"
                                >
                                </v-text-field>
                                <v-btn :small="$vuetify.breakpoint.xs" class="primary darken-1 white--text my-3"
                                       @click="logIn">{{logInfo.buttonText}}
                                </v-btn>
                                <v-alert
                                        dense
                                        outlined
                                        color="error"
                                        class="my-5"
                                        dismissible
                                        v-if="showError"
                                >
                                    {{errorMessage}}
                                </v-alert>
                            </v-form>
                        </v-card>

                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'AdminLogin',
  data() {
    return {
      role: 'Player',
      input: {
        username: null,
        groupNum: null,
        password: null
      },
      show1: false,
      showError: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters([
      "rules",
      "playerId"
    ]),
    logInfo() {
      if (this.role === 'Player') {
        return {
          title: 'An Event teilnehmen',
          placeholder1: 'Event-Name',
          placeholder2: 'PIN',
          placeholder3: 'Gruppen-Nr.',
          buttonText: 'Teilnehmen',
          description: 'Event-Daten eingeben'
        }
      } else if (this.role === 'TrailAdmin') {
        return {
          title: 'TrailAdmin – Login',
          placeholder1: 'Benutzername',
          placeholder2: 'Passwort',
          buttonText: 'Login',
          description: 'Anmeldedaten eingeben'
        }
      } else {
        return {
          title: 'EventAdmin – Login',
          placeholder1: 'Benutzername',
          placeholder2: 'Passwort',
          buttonText: 'Login',
          description: 'Anmeldedaten eingeben'
        }
      }
    }
  },
  methods: {
    async logIn() {
      if (this.$refs.form.validate()) {
        this.errorMessage ='';
        const User = {
          username: null,
          password: null
        };

        // for role is 'Player', the username is different than for EventAdmin & TrailAdmin
        if (this.role === 'Player') {
          // a player username is comprised of username, dash & group number
          User.username = this.input.username + "-" + this.input.groupNum
        } else {
          User.username = this.input.username;
        }
        User.password = this.input.password;

        try {
          await this.$store.dispatch('LogIn', User);
          if (this.$store.getters.isPlayer && this.role === 'Player') {
            this.$router.push({name: 'PlayerHome', params: {playerId: this.playerId}})
          } else if (this.$store.getters.isTrailAdmin && this.role === 'TrailAdmin') {
            this.$router.push({name: 'AdminTrailsAll'})
          } else if (this.$store.getters.isEventAdmin && this.role === 'EventAdmin') {
            this.$router.push({name: 'Events'})
          } else {
            this.errorMessage = 'Bitte überprüfe die Anmeldedaten oder melde dich mit dem richtigen User an.';
            console.error('Please check credentials or log in with the correct user.');
            this.showError = true;
          }
        } catch (e) {
          console.error(e);
          this.errorMessage = 'Ein Server-Problem ist aufgetreten. Mehr Infos im Log.';
          this.showError = true;
        }
      }
    },
  }
}
</script>
