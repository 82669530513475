export default {
  getAnswerType(answerType){
    var result;
    switch (answerType){
      case "text":
        result = "Text"
        break;
      case "number":
        result = "Nummer"
        break;
      case "singleChoice":
        result = "Single Choice"
        break;
      case "multipleChoice":
        result = "Multiple Choice"
        break;
      default:
        result = "-"
        break;
    }
    return result;
  }
}