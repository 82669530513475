<template>
  <v-container class="player-login overflow-hidden pa-0 fill-height">

    <v-card class="justify-center fill-height mt-0" rounded="0">

      <div class="codetrail-welcome-message position-absolute">
        <h1>Über CodeTrail</h1>
      </div>

      <v-img src="../assets/zhaw-volkart.jpg" height="calc(100% - 300px)"/>



      <v-card-text class="larger codetrail-challenge-desc text-center text--grey p-relative" style="height: 300px; font-size: 13px; padding-bottom: 80px">
        <p><b>Willkommen!</b> CodeTrail führt dich auf verschiedenen Routen durch die Stadt. Unterwegs musst du Rätsel lösen und dabei möglichst viele Punkte sammeln.</p>
        <p>Mit dem CodeTrail kannst du interessante Orte entdecken und dabei kleine Aufgaben aus dem Bereich der Informatik lösen.</p>
        <p>Viel Spass mit CodeTrail!</p>
      </v-card-text>
      
      <v-card-text class="codetrail-bottom-button-wrapper">
        <v-btn
            @click="$router.push('/login')"
            color="primary"
            class="pa-6 w-100"
        >
          Zurück zu den Trails
        </v-btn>
		<p></p>
		<v-btn
            @click="$router.push('/admin')"
            color="secondary"
            class="pa-6 w-100"
        >
          Admin
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
    export default {
    }
</script>

<style scoped>
.codetrail-welcome-message {
  left: 20px;
  top: 30px;
  z-index: 2;
}
.codetrail-welcome-message h1 {
  line-height: 1.1;
  font-weight: 100;
  font-size: 36px;
  text-shadow: 1px 1px 2px #ffffff40;
}
</style>