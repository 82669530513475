<template>
  <v-container>
    <v-tabs
      v-model="$store.state.eventTab"
      centered
      class="flex-wrap"
      height="35px"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        href="#tab-1"
        class="grey lighten-4"
        active-class="font-weight-bold"
      >
        Events
      </v-tab>
      <v-tab
        href="#tab-2"
        class="grey lighten-4"
        active-class="font-weight-bold"
      >
        Trails
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="$store.state.eventTab">
      <v-tab-item key="0" value="tab-1">
        <v-card flat>
          <v-card-text>
            <div>
              <v-btn
                text
                class="font-weight-bold"
                @click="addEventModal = true"
                :small="$vuetify.breakpoint.xs"
              >
                <v-icon left color="primary" :large="!$vuetify.breakpoint.xs"
                  >mdi-plus-circle-outline</v-icon
                >
                <span class="ml-2">Event erstellen</span>
              </v-btn>
            </div>
            <div>
              <v-row class="my-3">
                <v-col
                  md="6"
                  cols="12"
                  v-for="(item, idx) in events"
                  :key="'event_' + idx"
                >
                  <event-card v-if="!!item" :info="item"></event-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="1" value="tab-2">
        <v-card flat>
          <v-card-text>
            <div>
              <v-row class="my-3">
                <v-col
                  md="6"
                  cols="12"
                  v-for="item in trails"
                  :key="item.id"
                >
                  <event-trail-card
                    v-if="item"
                    :info="item"
                  ></event-trail-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="addEventModal" scrollable max-width="500">
      <v-card>
        <v-card-title
          class="px-1 px-sm-5 py-2 primary black--text text-uppercase text-sm-h6 text-subtitle-2"
        >
          Event erstellen
          <v-spacer></v-spacer>
          <v-btn @click="addEventModal = false" icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="addEventForm" style="max-height: 60vh; overflow-y: auto">
          <v-card-text>
            <div class="my-2">
              <label class="acme">*Event-Name</label>
              <v-text-field
                outlined
                color="primary"
                placeholder="Gib dem Challenge einen Namen"
                v-model="event.eventName"
                dense
                hide-details
                clearable
                required
                :rules="rules.requiredRules"
              >
              </v-text-field>
            </div>
            <div class="my-2">
              <label class="acme">Kurzbeschreibung</label>
              <v-textarea
                outlined
                rows="3"
                color="primary"
                placeholder="Füge dem Event eine Kurzbeschreibung hinzu."
                v-model="event.shortDescription"
                dense
                hide-details
                clearable
              >
              </v-textarea>
            </div>
            <div class="my-2">
              <label class="acme">*Event-Datum</label>
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    outlined
                    prepend-icon="mdi-calendar"
                    dense
                    required
                    :rules="rules.requiredRules"
                    :value="$date.dateFormatted(event.date)"
                    clearable
                    placeholder="Wann findet das Event statt?"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="event.date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="event.date"
                  locale="de-CH"
                  no-title
                  :first-day-of-week="1"
                  @change="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="my-2">
              <label class="acme">*Zeitfenster</label>
              <div class="d-flex">
                <div class="col my-0 py-0">
                  <label>Von</label>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="event.startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event.startTime"
                        hide-details
                        outlined
                        readonly
                        dense
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="rules.requiredRules"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu"
                      format="24hr"
                      v-model="event.startTime"
                      full-width
                      @click:minute="$refs.menu.save(event.startTime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
                <div class="col my-0 py-0">
                  <label>Bis</label>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="event.endTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="event.endTime"
                        hide-details
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="rules.requiredRules"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      format="24hr"
                      v-model="event.endTime"
                      full-width
                      @click:minute="$refs.menu2.save(event.endTime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <div class="text-center">
            <v-btn color="primary" class="br-md mt-3" @click="postEvent()"
              >Erstellen</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventCard from "../components/event-card";
import EventTrailCard from "../components/event-trail-card";

export default {
  components: { EventTrailCard, EventCard },
  data() {
    return {
      addEventModal: false,
      trail: {},
      event: {},
      menu: false,
      menu2: false,
      menu3: false,
      rules: this.$store.getters.rules,
      trails: [],
      events: [],
    };
  },
  methods: {
    async postEvent() {
      if (this.$refs.addEventForm.validate()) {
        var preparedEventJson = Object.assign({}, this.event);
        preparedEventJson.date = this.$date.formatDateForBackend(
          preparedEventJson.date
        );
        await this.$api.postEvent(preparedEventJson);
        this.addEventModal = false;
        // in order to immediately fetch just added event
        var res = await this.$api.getEvents();
        res.data.date = this.$date.formatDateForCalendar(res.data.date);
        this.events = res.data;
      } else {
        alert("Bitte alle benötigten Felder ausfüllen.");
      }
    },
    onFileChange(e) {
      var file = e;

      if (file) {
        this.createImage(file); // just one file => TODO: multiple files
      } else {
        // else block needed because this function runs on every change => even when removing image
        delete this.event.img;
      }
    },
    createImage(file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.event.img = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.onerror = (e) => {
        console.error(e);
      };
    },
  },
  async created() {
    const event_res = await this.$api.getEvents();
    this.events = event_res.data;
    const trail_res = await this.$api.getTrails();
    this.trails = trail_res.data;
  },
};
</script>