import Router from "vue-router";
import store from '../store'
import DashboardLayout from '@/layout/dashboardLayout'
import PlayerLayout from '@/layout/playerLayout'
import AuthLayout from '@/layout/authLayout'
import AdminLogin from '../views/AdminLogin.vue'
import AdminTrailsAll from '../views/AdminTrailsAll.vue'
import TrailEdit from '../views/TrailEdit.vue'
import TrailShow from '../views/TrailShow.vue'
import ChallengeEdit from '../views/ChallengeEdit.vue'
import ChallengeShow from '../views/ChallengeShow.vue'
import EventEdit from '../views/EventEdit.vue'
import Events from '../views/Events.vue'
import NotFound from '../views/NotFound.vue'
import PlayerLogin from '../views/PlayerLogin.vue'
import PlayerHome from '../views/PlayerHome.vue'
import PlayerTrail from '../views/PlayerTrail.vue'
import PlayerScore from '../views/PlayerScore.vue'
import About from '../views/About.vue'
import Vue from "vue";

Vue.use(Router);

const ifAuthenticatedPlayer = (to, from, next) => {
    next();
    if (store.getters.isAuthenticated) {
        if (store.getters.isPlayer) {
            next();
            return;
        } else {
            next('/403');
            return;
        }

    }
    next('/')
};
const ifAuthenticatedTrail = (to, from, next) => {
    next();
    if (store.getters.isAuthenticated) {
        if (store.getters.isTrailAdmin) {
            next();
            return;
        } else {
            next('/403');
            return;
        }
    }
    next('/')
};
const ifAuthenticatedEvent = (to, from, next) => {
    next();
    if (store.getters.isAuthenticated) {
        if (store.getters.isEventAdmin) {
            next();
            return;
        } else {
            next('/403');
            return;
        }
    }
    next('/')
};
const ifAuthenticated = (to, from, next) => {
    next();
    if (store.getters.isAuthenticated) {
        if (store.getters.isEventAdmin || store.getters.isTrailAdmin) {
            next();
            return;
        } else {
            next('/403');
            return;
        }
    }
    next('/')
};
const routes = [
    {
        path: '/',
        redirect: "login",
        component: PlayerLayout,
        children: [
            {
                path: 'about',
                name: 'About',
                component: About
            },
            {
                path: 'login',
                name: 'PlayerLogin',
                component: PlayerLogin
            },
            {
                path: 'event/:idPlayer',
                name: 'PlayerHome',
                component: PlayerHome,
                beforeEnter: ifAuthenticatedPlayer
            },
            {
                path: 'event/challenge',
                name: 'PlayerTrail',
                component: PlayerTrail,
                beforeEnter: ifAuthenticatedPlayer
            },
            {
                path: 'event/score/:idPlayer',
                name: 'PlayerScore',
                component: PlayerScore,
                beforeEnter: ifAuthenticatedPlayer
            }
        ]
    },
    {
        path: '/admin',
        redirect: 'AdminLogin',
        component: AuthLayout,
        children: [
            {
                path: '',
                name: 'AdminLogin',
                component: AdminLogin,
            },
        ]
    },
    {
        path: '/admin',
        component: DashboardLayout,
        children: [

            {
                path: 'trails/all',
                name: 'AdminTrailsAll',
                component: AdminTrailsAll,
                beforeEnter: ifAuthenticatedTrail,
            },
            {
                path: 'trail/:id',
                name: 'TrailEdit',
                component: TrailEdit,
                beforeEnter: ifAuthenticatedTrail,
            },
            {
                path: 'challenge/:id',
                name: 'ChallengeEdit',
                component: ChallengeEdit,
                beforeEnter: ifAuthenticatedTrail,
            },
            {
                path: 'challenge/show/:id',
                name: 'ChallengeShow',
                component: ChallengeShow,
                beforeEnter: ifAuthenticated,
            },
            {
                path: '/event',
                name: 'Events',
                component: Events,
                beforeEnter: ifAuthenticatedEvent,
            },
            {
                path: 'event/:id',
                name: 'EventEdit',
                component: EventEdit,
                beforeEnter: ifAuthenticatedEvent,
            },
            {
                path: 'trail/show/:id',
                name: 'TrailShow',
                component: TrailShow,
                beforeEnter: ifAuthenticated,
            },
        ]
    },
    {
        path: '/403',
        name: '403',
        component: () => import(/* webpackChunkName: "403" */ '../views/403.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    }
];
export default new Router({
    mode: "history",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
})

