import moment from "moment";

export default {
  standardDateFormat: "YYYY-MM-DD",
  germanDateFormat: "DD.MM.YYYY", // backend date format: "D.MM.YYYY"
  standardTimeFormat: "HH:mm:ss",

  formatTime(time) {
    // "HH:mm:ss" => time format from backend
    return moment(time, "HH:mm:ss").format(this.standardTimeFormat);
  },
  dateFormatted(date) { // this is just to show date in a german/swiss format
    return date ? moment(date).format(this.germanDateFormat) : "";
  },
  formatDateForBackend(date) { // this function changes frontend format in order to meet backend requirements
    return moment(date).format(this.germanDateFormat)
  },
  formatDateForCalendar(date) { // this function changes backend format in order to meet v-date-picker requirements
    return moment(date, "D.MM.YYYY").format(this.standardDateFormat)
  },
  formatDateForScore(date) {
    return moment(date, "HH:mm:ss:SSS").format(this.standardTimeFormat)
  }
}