<template>
  <div class="ma-0 pa-0 overflow-hidden fill-height">
    <v-app-bar class="p-relative" fixed height="64px">
      <v-app-bar-nav-icon
          @click.stop="drawer =!drawer"
          class="position-absolute"
          style="left: 12px;"
      ></v-app-bar-nav-icon>

      <div>
        <div class="codetrail-header-stats-wrapper pl-2">
          <div>
          <span class="num" :style="'color: ' + colors.primary">
            {{challengeStep + 1}}/{{numChallenges}}
          </span>
          </div>
          <div>
            <span class="type grey--text text--darken-1">Posten</span>
          </div>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-btn
          rounded
          dark
          dense
          color="transparent"
          height="30px"
          elevation="0"
          :style="'border: 1px solid ' + colors.primary + ' !important'"
      >
        <v-img src="../assets/zhaw_logo.png" height="16" width="16" class="pr-4"/>
        <span class="pl-1 text-decoration-none codetrail-badge-text">CodeTrail</span>
      </v-btn>

      <v-spacer></v-spacer>

      <div class="codetrail-header-stats-wrapper">
        <div>
          <span class="num" :style="'color: ' + colors.primary">
            <span :class="closeTime ? 'red--text' : ''">{{timeLeftFormatted ? timeLeftFormatted : "00:00:00"}}</span>
          </span>
        </div>
        <div>
          <span class="type grey--text text--darken-1">Verbleibende Zeit</span>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app fixed :clipped="true">

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
              Übersicht Posten
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
            v-for="challenge in challenges"
            :key="challenge.title"
            link
            :disabled="challenge.state === 'unavailable'"
        >
          <v-list-item-icon v-if="challenge.type === 'info'">
            <v-icon color="blue darken-1">mdi-information-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-icon v-else-if="challenge.type === 'transfer'">
            <v-icon color="cyan darken-2">mdi-routes</v-icon>
          </v-list-item-icon>

          <v-list-item-icon v-else>
            <v-icon color="red lighten-2">mdi-map-marker-question-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
                @click="goToChallenge(challenge.number)"
                :class="{'text--primary': challenge.state === 'current'}"
            >
              {{ challenge.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="leaveTrail" color="error" class="text-capitalize">
            {{ isPlayer ? 'Event verlassen' : 'Logout' }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-container class="codetrail-container p-relative overflow-hidden pa-0 w-100">
      <transition :name="transitionName" class="codetrail-slider" style="overflow: hidden; position: relative; height: 100%; width: 100%">
        <div class="fill-height position-absolute w-100" v-bind:key="currentChallenge.id">
          <v-card class="mt-0 fill-height justify-center fill-height w-100" elevation="0" rounded="0">

            <div class="fill-height overflow-y-auto" :style="'padding-bottom: ' + solutionWrapperHeight" ref="challengeContent">

              <div class="header-image" style="height: 200px">
                <div class="date-circle blue darken-1" v-if="isTypeInfo">
                  <v-icon color="#ffffffe0">mdi-information-variant</v-icon>
                </div>

                <div class="date-circle red lighten-2" v-if="isTypeQuestion">
                  <v-icon color="#ffffffe0">mdi-map-marker-question-outline</v-icon>
                </div>

                <div class="date-circle cyan darken-2" v-if="isTypeTransfer">
                  <v-icon color="#ffffffe0">mdi-routes</v-icon>
                </div>

                <v-img
                    v-if="!!currentChallenge.rest.img"
                    :src="currentChallenge.rest.img"
                    :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
                    style="max-height: 200px"
                    v-click-outside="onClickOutside"
                ></v-img>
                <v-img
                    v-else
                    class="d-flex align-center grey"
                    left="auto"
                    right="auto"
                    :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
                >
                  <v-icon class="d-flex" x-large color="primary">mdi-image-area</v-icon>
                </v-img>
              </div>

              <div class="challenge-description">
                <v-card-title class="challenge-title justify-center mb-0 font-weight-bold">
                  {{ currentChallenge.rest.name }}
                </v-card-title>

                <div class="v-card__text">
                  <div class="codetrail-challenge-desc text-justify px-1 text--grey p-relative" v-html="currentChallenge.frage">
                    {{ currentChallenge.frage }}
                  </div>

                  <v-alert
                      v-if="numTipsUsed > 0"
                      color="orange"
                      icon="mdi-lightbulb-on"
                      border="left"
                      class="mt-8"
                      colored-border
                      elevation="3"
                  >
                    <small>
                      <ol>
                        <li v-for="(tip, nTip) in tipsUsed" v-bind:key="'tip'+nTip">{{tip.text}}</li>
                      </ol>
                    </small>
                  </v-alert>
                </div>
              </div>

              <div class="codetrail-bottom-button-wrapper w-100 top-shadow" ref="solutionBox">
                <v-card-text class="fill-height">
                  <div class="solution-wrapper fill-height w-100 p-relative">
                    <v-form ref="codetrailForm">
                      <div v-if="currentChallenge.type === 'text'">
                        <v-textarea
                            type="text"
                            placeholder="Antworte hier in Textform"
                            :disabled="isPrevStep || !timeLeft"
                            outlined
                            hide-details
                            v-model="answer"
                            :color="isPrevStep ? colors.error : colors.primary"
                            :append-icon="isPrevStep ? 'mdi-pencil-off' : null"
                            class="font-weight-bold"
                            height="72px"
                            required
                            :rules="rulesText"
                        >
                        </v-textarea>
                      </div>
                      <div v-else-if="currentChallenge.type === 'number'">
                        <v-text-field
                            type="number"
                            placeholder="Bitte Nummer eintragen"
                            :disabled="isPrevStep|| !timeLeft"
                            outlined
                            hide-details
                            v-model="answer"
                            :append-icon="isPrevStep ? 'mdi-pencil-off' : null"
                            :color="isPrevStep ? colors.error : colors.primary"
                            class="font-weight-bold"
                            required
                            :rules="rulesNumber"
                        >
                        </v-text-field>
                      </div>
                      <div v-else-if="currentChallenge.type === 'singleChoice'" class="mt-0 pb-2">
                        <v-radio-group
                            v-model="answer"
                            column
                            class="ma-0 pa-0"
                            hide-details
                            :rules="rulesRadio"
                        >
                          <v-radio
                              v-for="(choice, idx) in currentChallenge.rest
                                .possibleSolutions"
                              :key="'ch_' + idx"
                              :value="idx"
                              :disabled="isPrevStep || !timeLeft"
                          >
                            <template v-slot:label>
                              <div class="single-choice-radio py-1" v-html="choice.value"></div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <v-icon v-if="isPrevStep" class="codetrail-not-editable-icon">
                          mdi-pencil-off
                        </v-icon>
                      </div>
                      <div v-else-if="currentChallenge.type === 'multipleChoice'" class="pb-2">
                        <v-checkbox
                            multiple
                            dense
                            hide-details
                            v-model="answer"
                            v-for="(choice, idx) in currentChallenge.rest
                              .possibleSolutions"
                            :key="'chm_' + idx"
                            :value="idx"
                            :disabled="isPrevStep || !timeLeft"
                        >
                          <template v-slot:label>
                            <div class="multiple-choice-checkbox py-1" v-html="choice.value"></div>
                          </template>
                        </v-checkbox>
                        <v-icon v-if="isPrevStep" class="codetrail-not-editable-icon">
                          mdi-pencil-off
                        </v-icon>
                      </div>

                      <v-row class="mt-2" dense>
                        <v-col class="text-no-wrap" :cols="!canCallForTip ? 6 : 3" :class="{'d-none': !canGoBack}">
                          <v-btn
                              color="secondary"
                              class="pa-6 w-100"
                              @click="goToPrevChallenge"
                          >
                            Zurück
                          </v-btn>
                        </v-col>
                        <v-col :cols="!canGoBack ? 6 : 3" :class="{'d-none': !canCallForTip}">
                          <v-btn
                              v-if="canCallForTip"
                              @click="useTip(numTipsUsed)"
                              color="orange"
                              dark
                              class="pa-6 w-100"
                          >
                            Tipp
                          </v-btn>
                        </v-col>
                        <v-col :cols="canCallForTip || canGoBack ? 6 : 12">
                          <v-btn
                              v-if="!isPrevStep"
                              @click="submitChallenge"
                              color="primary"
                              class="pa-6 w-100"
                          >
                            {{ buttonText }}
                          </v-btn>
                          <v-btn
                              v-else
                              @click="goToNextChallenge"
                              color="primary"
                              class="pa-6 w-100"
                          >
                            {{ buttonText }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </div>
      </transition>
    </v-container>

    <v-overlay v-if="isFullScreen && !!currentChallenge.rest.img" opacity="0.8">
      <v-img :src="currentChallenge.rest.img" max-height="100%" max-width="100%"></v-img>
    </v-overlay>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventPlayerMixin from "@/mixins/eventPlayerMixin";

export default {
  name: 'PlayerTrail',
  mixins:[eventPlayerMixin],
  data: function () {
    return {
      transitionName: "slide",
      drawer: false,
      ERROR_CODES: this.$api.ERROR_CODES,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true,
        },
      },
      answer: null,
      disabled: false,
      challengeStep: 0,
      clickedSubmit: false,
      challenges: [],
      currentChallenge: {
        id: "",
        rest: {}
        },
      currentChallengeTips: [],
      refreshTimer: null,
      solutionWrapperHeight: "120px",
      isFullScreen: false,
    };
  },
  computed: {
    ...mapGetters([
      "timeLeft",
      "isTimerRunning",
      "timeLeftFormatted",
      "playerId",
      "event",
      "closeTime",
      "isPlayer"
    ]),

    completedSteps() {
      return this.challenges.filter(ch => ch.state === "solved").map(ch => ch.number);
    },

    numChallenges() {
      return this.challenges.length
    },

    rulesText() {
      let rules = [];
      const rule = v => v && !!v.trim() || 'Bitte einen Wert angeben';
      rules.push(rule);
      return rules;
    },

    rulesNumber() {
      let rules = [];
      const rule = v => v && v > -1 || 'Bitte einen Wert angeben';
      rules.push(rule);
      return rules;
    },

    rulesRadio() {
      let rules = [];
      const rule = v => v != null || 'Bitte einen Wert auswählen';
      rules.push(rule);
      return rules;
    },

    colors() {
      return this.$vuetify.theme.themes.light;
    },

    isTypeInfo() {
      return this.currentChallenge.type === "info";
    },

    isTypeQuestion() {
      return !this.isTypeInfo && !this.isTypeTransfer; // todo ergänzen um single, multiple number
    },

    isTypeTransfer() {
      return this.currentChallenge.type === "transfer";
    },

    numTips() {
      return this.currentChallengeTips.length;
    },

    numTipsUsed() {
      return this.tipsUsed.length;
    },

    tipsUsed() {
      return this.currentChallengeTips.filter(t => t.tippState === "used");
    },

    hasTipsLeft() {
      return this.numTips > this.numTipsUsed;
    },

    canCallForTip() {
      return this.hasTipsLeft && !this.isPrevStep;
    },

    isPrevStep() {
      return this.challengeStep !== this.completedSteps.length;
    },

    solution() {
      let solution = "";
      if(this.isPrevStep && typeof this.challenges[this.challengeStep] !== "undefined") {
        solution = this.challenges[this.challengeStep].solution;
      }
      return solution;
    },

    canGoBack() {
      return this.challengeStep !== 0;
    },

    buttonText() {
      switch(true) {
        case this.completedSteps.length + 1 >= this.numChallenges && this.challengeStep + 1 === this.numChallenges:
          return "Trail beenden";
        case this.isPrevStep:
          return "Weiter";
        case this.isTypeInfo:
          return "Weiter";
        case this.isTypeTransfer:
          return "Ziel gefunden";
        default:
          return "Antwort speichern";
      }
    }
  },
  watch: {
    // this watcher checks if the event is finished when no step is opened
    async challengeStep() {
      let status = await this.isTrailNotFinished();
      if(!status) {
        this.gotoScore().then();
      }
    }
  },
  async created() {
    // check if the player is allowed to do the trail..
    if(await this.checkPlayerTrailStatus()) {
      // .. if so load the challenges.
      await this.getCurrentChallenge();
      // and update challenge list
      await this.getChallenges();
      // get the last challenge step
      this.challengeStep = this.challenges.find(ch => ch.state === "current").number;


      // add windows resize event listener
      window.addEventListener("resize", this.onWindowsResize);

      // unset and reset timer on visibility changes
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          this.clearTimeLeftInterval();
        } else {
          this.setTimeLeft();
        }
      })


    } else {
      // ..if not redirect and reset time left
      this.$router.push({name: "PlayerScore", params: { idPlayer: this.playerId }}).then();
      this.$store.commit("setClearInterval", true); // reset time_left
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowsResize);
    this.$store.commit("setClearInterval", true); // reset time_left
  },
  methods: {

    onWindowsResize() {
      console.log("onWindowsResize");
      this.solutionWrapperHeight = this.$refs.solutionBox.clientHeight + "px";
    },

    // checks if current player is allowed to do the trail
    async checkPlayerTrailStatus() {
      let status = await this.$api.checkTrailStatus();
      return status === this.ERROR_CODES.ERROR_TRAIL_NOT_FINISHED;
    },

    // jump back to a challenge by id
    async goToChallenge(challengeIndex) {
      // first check if challenge id is existing
      if(typeof this.challenges[challengeIndex] !== "undefined") {
        let res = await this.$api.getChallengeById(challengeIndex);
        if(res) {
          this.currentChallenge = res.data;
          this.challengeStep = challengeIndex;
          this.getCurrentChallengeTips().then(); // load also current challenge tips
          if(this.isPrevStep) {
            this.answer = this.solution;
            if(this.currentChallenge.type === "singleChoice") {
              this.answer = parseInt(this.answer)
            } else if(this.currentChallenge.type === "multipleChoice") {
              this.answer = JSON.parse(this.answer)
            }
          }
          // recalculate wrapper height
          this.$nextTick(() => {
            this.solutionWrapperHeight = this.$refs.solutionBox.clientHeight + "px";
          });
        }
      }
    },

    async goToNextChallenge() {
      this.transitionName = "slide";
      this.goToChallenge(this.challengeStep + 1).then();
    },

    async goToPrevChallenge() {
      this.transitionName = "slideback";
      this.goToChallenge(this.challengeStep - 1).then();
    },

    // This is called after submitting a solution. It performs all the step that are required
    // to load a new challenge.
    async nextStep() {
      this.clickedSubmit = false;
      this.challengeStep = this.challengeStep + 1;

      // load next challenge
      await this.getCurrentChallenge();
      // and update challenge list
      await this.getChallenges();
      this.$forceUpdate(); // because for an unknown reason, time will not count (update)
      // this.answer = null;
      this.$refs.codetrailForm.reset();
    },

    // submit an answer
    async submitChallenge() {

      if(!this.$refs.codetrailForm.validate()) {
        return;
      }

      // Prev challenges can not be submitted again. Instead jump to current challenge
      if(this.isPrevStep) {
        return this.goToChallenge(this.completedSteps.length).then();
      }

      // only submit questions types
      if(!this.isTypeQuestion) {
        this.answer = "";
      }

      // add current challenge to the completed steps
      this.currentChallenge.postedSolution = this.answer;

      // stringify solutions of type singleChoice & multipleChoice (array types)
      if (this.currentChallenge.type === "singleChoice" || this.currentChallenge.type === "multipleChoice") {
        this.currentChallenge.postedSolution = JSON.stringify(this.currentChallenge.postedSolution);
      }

      // post players solution
      let res = await this.$api.postSolution({
        solution: this.currentChallenge.postedSolution
      });

      if(res) {
        if (!this.completedSteps.includes(this.challengeStep)) {
          this.completedSteps.push(this.challengeStep);
        }
      }

      // When all challenges are solved go to score page instead of loading next step
      if (this.completedSteps.length >= this.numChallenges) {
        await this.gotoScore();
      } else {
        // otherwise go to the next challenge
        await this.nextStep();
      }
    },

    // Makes a backend request and marks a tip as used. Then the tip content is returned
    async useTip(idx) {
      var tipUsed = await this.$api.useTip(idx);

      if(tipUsed) {
        await this.getCurrentChallengeTips();

        // scroll to tip
        const el = this.$refs.challengeContent;
        if (el) {
          this.$nextTick(() => {
            el.scroll({
              top: el.scrollHeight,
              behavior: 'smooth'
            })
          });
        }
      }
    },

    // handles errors that can occur when loading the current challenge
    onLoadChallengeError(e) {
      switch (e) {
        case this.ERROR_CODES.ERROR_EVENT_FINISHED:
          this.$store.commit("setTimeLeftGeneral", null);
          this.$store.commit("setClearInterval", true); // reset time_left
          this.$router.push({ name: "PlayerHome" });
          alert(this.ERROR_CODES.ERROR_EVENT_FINISHED);
          break;
        case this.ERROR_CODES.ERROR_EVENT_NOT_STARTED:
          this.$store.commit("setTimeLeftGeneral", null);
          this.$store.commit("setClearInterval", true); // reset time_left
          this.$router.push({ name: "PlayerHome" });
          alert(this.ERROR_CODES.ERROR_EVENT_NOT_STARTED);
          break;
        case this.ERROR_CODES.ERROR_EVENT_NOT_AVAILABLE:
          this.$store.commit("setTimeLeftGeneral", null);
          this.$store.commit("setClearInterval", true); // reset time_left
          this.$router.push({ name: "PlayerHome" });
          alert(this.ERROR_CODES.ERROR_EVENT_NOT_AVAILABLE);
          break;
        default:
          console.log("Unknown error when loading the current challenge: " + e)
          break;
      }
    },

    // Loads the current challenge from backend
    async getCurrentChallenge() {
      let res = await this.$api.getCurrentChallenge(this.onLoadChallengeError);
      if(res) {
        this.currentChallenge = res.data;
        await this.getCurrentChallengeTips(); // load also current challenge tips

        // recalculate wrapper height
        this.$nextTick(() => {
          this.solutionWrapperHeight = this.$refs.solutionBox.clientHeight + "px";
        });
      }
    },

    async getChallenges() {
      let res = await this.$api.getCurrentChallenges();
      if(res) {
        this.challenges = res.data;
      }
    },

    // Loads the current challenges tips from the backend
    async getCurrentChallengeTips() {
      let currentChallengeTips = await this.$api.getTips(this.challengeStep);
      if(currentChallengeTips) {
        this.currentChallengeTips = currentChallengeTips.data;
      } else {
        this.currentChallengeTips = []; // empty tips from last step
      }
    },

    async leaveTrail() {
      await this.$store.dispatch("LogOut");
      this.$store.commit("setClearInterval", true); // reset time_left
      this.$router.push("/").then();
    },

    onClickOutside () {
      this.isFullScreen = false
    },
  }
};
</script>

<style scoped>

.codetrail-container {
  height: calc(100% - 64px);
  margin-top: 64px;
}

.codetrail-badge-text {
  text-transform: none !important;
  color: #777;
  font-weight: 600;
  letter-spacing: normal;
  background: none;
}

.codetrail-not-editable-icon {
  position:absolute;
  right: 0;
  top: 12px
}

.codetrail-header-stats-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  line-height: 1;
}
.codetrail-header-stats-wrapper .num {
  font-size: 14px;
  font-weight: bold;
}
.codetrail-header-stats-wrapper .type {
  font-size: 12px;
}

.codetrail-challenge-desc {
  font-size: 13px;
}

.date-circle {
  text-shadow: 1px 1px 2px #ffffff40;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 0.8;
  color: #ffffffe3;
  text-align: center;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 36px;
  width: 36px;
  z-index: 2;
  border-radius: 30px !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12) !important;
}

ol.circle {
  list-style: none;
  counter-reset: item;
}

ol.circle > li{
  counter-increment: item;
  margin-bottom: 5px;
}

ol.circle > li:before {
  margin-right: 10px;
  content: counter(item);
  background: #FF8A65;
  border-radius: 100%;
  color: white;
  width: 1.3em;
  text-align: center;
  display: inline-block;
}

.top-shadow {
  background-color: #fff;
  -webkit-box-shadow: 0 -20px 20px 10px #fff;
  box-shadow: 0 -20px 20px 10px #fff;
}

</style>

<style>
.v-application--is-ltr .v-alert__icon {
  margin-right: 0 !important;
}
.single-choice-radio p,
.multiple-choice-checkbox p {
  margin: 0 !important;
}


.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}


.codetrail-challenge-desc img {
  max-width: 100%;
}

</style>