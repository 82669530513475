<template>
  <v-container>
    <v-btn
      @click="$router.push({ name: 'AdminTrailsAll' })"
      outlined
      icon
      class="mb-2"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-row>
      <v-col sm="4" cols="12">
        <v-card>
          <v-img :src="challenge.rest.img" height="200px"></v-img>
        </v-card>
      </v-col>
      <v-col sm="8" cols="12">
        <v-card-actions class="justify-space-around pt-3 flex-wrap px-0">
          <v-dialog max-width="500" scrollable v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                class="br-md ma-1"
                @click="getTrails()"
                :small="$vuetify.breakpoint.xs"
                block
                v-bind="attrs"
                v-on="on"
                >Zu Trail hinzufügen
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title
                  class="px-1 px-sm-5 py-2 primary black--text text-uppercase text-sm-subtitle-1 text-subtitle-2"
                >
                  Zu Trail hinzufügen
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog.value = false" icon color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text
                  class="pa-1"
                  style="max-height: 60vh; overflow-y: auto"
                >
                  <v-list>
                    <v-list-item-group
                      v-model="selectedTrail"
                      mandatory
                      active-class="border"
                      color="info"
                    >
                      <v-list-item
                        v-for="(item, idx) in trails"
                        :key="'trail__' + idx"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content class="pa-1">
                            <v-card class="ma-0">
                              <v-card-text class="pa-2">
                                <v-row
                                  class="justify-space-between align-center"
                                >
                                  <v-col cols="12">
                                    <div class="acme">{{ item.trailName }}</div>
                                    <div
                                      class="d-flex flex-wrap justify-space-between"
                                    >
                                      <div class="black--text col pa-0">
                                        Zeit:
                                      </div>
                                      <div>{{ item.duration }}'</div>
                                    </div>
                                    <div
                                      class="d-flex flex-wrap justify-space-between"
                                    >
                                      <div class="black--text col pa-0">
                                        Challenges:
                                      </div>
                                      <div v-if="!!item.challenges">{{ item.challenges.length }} Stk.</div>
                                      <div v-else>0 Stk.</div>
                                    </div>
                                    <div
                                      class="d-flex flex-wrap justify-space-between"
                                    >
                                      <div class="black--text col pa-0">
                                        Kurzbeschreibung:
                                      </div>
                                      <div>{{ item.rest.shortDescription }}</div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="primary"
                    :small="$vuetify.breakpoint.xs"
                    class="ma-2"
                    @click="addChallengeToTrail()"
                    >Hinzufügen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-dialog max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="fruit"
                class="br-md ma-1"
                :block="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.xs"
                v-bind="attrs"
                v-on="on"
                >Challenge löschen
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-title class="py-2 fruit black--text">
                  ACHTUNG
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog.value = false" icon color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="py-4"
                  >MÖCHTEST DU WIRKLICH DIESE CHALLENGE LÖSCHEN?
                  <br />
                  <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.
                  <div class="d-flex justify-space-around my-3">
                    <v-btn
                      color="info"
                      :small="$vuetify.breakpoint.xs"
                      class="ma-2"
                      @click="dialog.value = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      @click="deleteChallenge()"
                      color="primary"
                      :small="$vuetify.breakpoint.xs"
                      class="ma-2"
                      >Löschen
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>

          <v-btn
            color="primary"
            class="br-md ma-1"
            :small="$vuetify.breakpoint.xs"
            :block="$vuetify.breakpoint.xs"
            @click="checkInput()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
        <v-card>
          <v-card-text>
            <v-form
              ref="editChallenge"
              style="max-height: 60vh; overflow-y: auto"
            >
              <v-card-text>
                <div class="my-2">
                  <label class="acme">*Challenge-Name</label>
                  <v-text-field
                    outlined
                    color="primary"
                    placeholder="Gib der Challenge einen Namen."
                    v-model="challenge.rest.name"
                    required
                    dense
                    :rules="rules.requiredRules"
                    hide-details
                    clearable
                  >
                  </v-text-field>
                </div>
                <div class="my-2">
                  <label class="acme">*Challenge-Beschreibung</label>
                  <ckeditor
                      type="classic"
                      v-model="challenge.frage"
                      :config="editorConfig"
                      placeholder="Hier kommt die Beschreibung und die Frage hin."
                  ></ckeditor>
                </div>
                <div class="my-2">
                  <label class="acme">Antworttyp</label>
                  <v-radio-group
                    v-model="challenge.type"
                    @change="
                      uncheckAll();
                      challenge.type = $event;
                    "
                    row
                    dense
                    required
                    hide-details
                    :rules="rules.requiredRules"
                  >
                    <v-radio label="Text" value="text"></v-radio>
                    <v-radio label="Number" value="number"></v-radio>
                    <v-radio label="Single Choice" value="singleChoice"></v-radio>
                    <v-radio label="Multiple Choice" value="multipleChoice"></v-radio>
                    <v-radio label="Wegbeschreibung" value="transfer"></v-radio>
                    <v-radio label="Informationen" value="info"></v-radio>
                  </v-radio-group>
                </div>
                <div class="my-2" v-if="challenge.type === 'text'">
                  <label class="acme">*Lösung:</label>
                  <v-text-field
                    v-model="challenge.solution"
                    outlined
                    dense
                    placeholder="Text als Lösung"
                    type="text"
                    hide-details
                    required
                    :rules="rules.requiredRules"
                    clearable
                  >
                  </v-text-field>
                </div>
                <div class="my-2" v-else-if="challenge.type === 'number'">
                  <label class="acme">*Lösung:</label>
                  <v-text-field
                    v-model="challenge.solution"
                    outlined
                    dense
                    placeholder="Nummer als Lösung"
                    type="number"
                    hide-details
                    required
                    :rules="rules.requiredRules"
                    clearable
                  >
                  </v-text-field>
                </div>
                <div class="my-2" v-else-if="challenge.type !== 'info' && challenge.type !== 'transfer'">
                  <label class="acme">*Antwortmöglichkeiten:</label>
                  <div
                    v-for="(choice, idx) in challenge.rest.possibleSolutions"
                    :key="'choice' + idx"
                    class="mt-3 d-flex flex-column"
                  >
                    <div class="mt-3 d-flex">
                      <ckeditor
                        type="classic"
                        v-model="choice.value"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-btn
                        color="fruit"
                        small
                        @click="deleteChoice(idx)"
                        icon
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex flex-row">
                      <v-icon
                        v-if="disabled == true"
                        @click="iconClicked()"
                        color="fruit"
                        class="ml-2"
                        >mdi-close</v-icon
                      >
                      <v-checkbox
                        v-if="challenge.type === 'singleChoice'"
                        @change="isSingleChoice()"
                        class="my-1"
                        label="Ist korrekt"
                        :value="idx"
                        v-model="challenge.solution"
                        :disabled="disabled"
                        hide-details
                      >
                      </v-checkbox>
                      <v-checkbox
                        v-else
                        class="my-1"
                        label="Ist korrekt"
                        :value="idx"
                        v-model="challenge.solution"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </div>
                  <div class="text-right my-3">
                    <v-btn
                      :small="$vuetify.breakpoint.xs"
                      color="primary"
                      @click="addChoice"
                      >Antwort hinzufügen
                    </v-btn>
                  </div>
                </div>
                <div class="my-2">
                  <label class="acme">Bild</label>
                  <v-file-input
                    @change="onFileChange"
                    accept="image/png,image/jpg"
                    outlined
                    dense
                    placeholder="Füge ein Bild für diese Challenge ein."
                    clearable
                  ></v-file-input>
                </div>
                <div>
                  <div class="acme text-h6">Tipps</div>
                  <v-card
                    elevation="3"
                    v-for="(tip, idx) in challenge.tipp"
                    :key="'tip_' + idx"
                  >
                    <v-card-title
                      class="my-0 pt-2 pb-1 text-subtitle-1 font-weight-bold"
                    >
                      Tipp {{ idx + 1 }}
                      <v-btn color="fruit" small @click="deleteTip(idx)" icon>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text class="my-1">
                      <v-row class="my-0" align="center">
                        <v-text-field
                          class="mx-2 my-1"
                          label="Titel"
                          rows="3"
                          maxlength="15"
                          counter
                          hint="Der Titel sollte nicht zu viel verraten."
                          v-model="challenge.tipp[idx].title"
                          outlined
                          dense
                          type="text"
                          required
                          :rules="rules.requiredRules"
                          clearable
                        ></v-text-field>
                        <v-textarea
                          class="mx-2 my-1"
                          label="Text"
                          rows="3"
                          v-model="challenge.tipp[idx].text"
                          outlined
                          dense
                          type="text"
                          hide-details
                          required
                          :rules="rules.requiredRules"
                          clearable
                        ></v-textarea>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <div class="my-2">
                    <v-btn
                      text
                      class="font-weight-bold"
                      :small="$vuetify.breakpoint.xs"
                      @click="addTip"
                    >
                      <v-icon
                        left
                        color="primary"
                        :large="!$vuetify.breakpoint.xs"
                      >
                        mdi-plus-circle-outline
                      </v-icon>
                      <span class="ml-2">Tipp hinzufügen</span>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "challengeEdit",
  data() {
    return {
      editorConfig: {
        placeholder: "Text einfügen...",
        required: true,
        toolbar: {
          items: [
            'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true,
        },
      },
      rules: this.$store.getters.rules,
      disabled: false,
      dialog: false,
      challenge: {
        solution: [],
        tipp: [],
        rest: {
          possibleSolutions: [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
          ],
        },
      },
      selectedTrail: null,
      trails: [],
    };
  },
  methods: {
    uncheckAll() {
      this.disabled = false;
      this.challenge.solution = [];
    },
    isSingleChoice() {
      this.disabled = this.challenge.solution.length > 0;
    },
    iconClicked() {
      this.disabled = !this.disabled;
      this.challenge.solution = [];
    },
    deleteChoice(idx) {
      if (this.challenge.rest.possibleSolutions.length > 2) {
        this.challenge.rest.possibleSolutions.splice(idx, 1);
      } else {
        this.$store.dispatch("set_alert", {
          msg: "Min. 2 mögliche Antworten",
          type: "error",
        });
      }
    },
    addChoice() {
      if (this.challenge.rest.possibleSolutions.length < 4) {
        this.challenge.rest.possibleSolutions.push({ value: "" });
      } else {
        this.$store.dispatch("set_alert", {
          msg: "Max. 4 mögliche Antworten",
          type: "error",
        });
      }
    },
    addTip() {
      this.challenge.tipp.push({
        title: "",
        text: "",
      });
    },
    deleteTip(idx) {
      this.challenge.tipp.splice(idx, 1);
    },
    onFileChange(e) {
      // TODO: reduce code duplication (same function in AdminTrailsAll.vue)
      var file = e;

      if (file) {
        this.createImage(file); // just one file => TODO: multiple files
      } else {
        // else block needed because this function runs on every change => even when removing image
        delete this.challenge.rest.img;
      }
    },
    createImage(file) {
      // TODO: reduce code duplication (same function in AdminTrailsAll.vue)
      var reader = new FileReader();

      reader.onload = (e) => {
        this.challenge.rest.img = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.onerror = (e) => {
        console.error(e);
      };
    },
    async addChallengeToTrail(){
      var idTrail = this.trails[this.selectedTrail].id
      var res = await this.$api.addChallengeToTrail(idTrail, {id: this.challenge.id});
      if(res === this.$api.ERROR_CODES.ERROR_CHALLENGE_ALREADY_EXISTS){
        alert("Challenge bereits vorhanden");
      } else {
        this.dialog = false;
        this.getTrails();
      }
    },
    async getTrails(){
      const res = await this.$api.getTrails();
      this.trails = res.data;
    },
    async deleteChallenge() {
      await this.$api.deleteChallenge(this.$route.params.id);
      this.$router.push({ name: "AdminTrailsAll" });
    },
    async editChallenge() {
      if (this.$refs.editChallenge.validate()) {
        if (
          this.challenge.type == "singleChoice" ||
          this.challenge.type == "multipleChoice"
        ) {
          // only stringify if solution is of type array => which singleChoice & multipleChoice are
          this.challenge.solution = JSON.stringify(this.challenge.solution); // necessary because backend can only process strings, not array
        }
        await this.$api.editChallenge(this.$route.params.id, this.challenge);
        
        /* ONLY USE THIS CODE BELOW IF "this.$api.editChallenge" DOES NOT ALLOW TO ALSO ADD TIPS TO THE REQUEST */
        /* --------------------------------------- */
        /* if (this.challenge.tipp.length > 0) {
          console.log("postTipsToChallenge");
          await this.$api.postTipsToChallenge(this.$route.params.id,this.challenge.tipp);
          this.challenge.tipp = [];
        } */
        /* --------------------------------------- */

        // in order to immediately fetch just updated challenge
        var res = await this.$api.getChallenge(this.$route.params.id);
        if (
          res.data.type == "singleChoice" ||
          res.data.type == "multipleChoice"
        ) {
          res = this.prepareResponse(res);
        }
        this.challenge = res.data;
      } else {
        alert("Bitte alle benötigten Felder ausfüllen.");
      }
    },
    checkInput() {
      var isSingleChoice = this.challenge.type === "singleChoice";
      var isMultipleChoice = this.challenge.type === "multipleChoice";
      if (isSingleChoice || isMultipleChoice) {
        // cannot check directly like other fields, therefore checking here
        if (this.challenge.solution.length != 0) {
          this.editChallenge();
        } else {
          alert("Bitte eine Lösung auswählen");
        }
      } else {
        this.editChallenge();
      }
    },
    prepareResponse(res) {
      res.data.solution = JSON.parse(res.data.solution);
      return res;
    },
  },
  async created() {
    var res = await this.$api.getChallenge(this.$route.params.id);
    if (res.data.type == "singleChoice" || res.data.type == "multipleChoice") {
      res = this.prepareResponse(res);
    }
    this.challenge = res.data;
  },
};
</script>

<style scoped>
</style>