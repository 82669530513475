import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import API from "./plugins/api";
import answerType from "./plugins/answerType";
import date from "./plugins/formatDate";
import './assets/app.scss'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import VueCkeditor from 'vue-ckeditor5'

const options = {
  editors: {
    classic: ClassicEditor,
  },
  name: 'ckeditor'
}

Vue.use(require('vue-moment'));
Vue.use(VueCkeditor.plugin, options);
Vue.prototype.$api = API;
Vue.prototype.$answerType = answerType;
Vue.prototype.$date = date;
Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
