<template>
  <v-container>
    <v-btn @click="$router.push({ name: 'Events' })" outlined icon class="mb-2">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-row>
      <v-col sm="4" cols="12">
        <v-img
          v-if="!!event.rest.img"
          :src="event.rest.img"
          :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
        ></v-img>
        <v-img
          v-else
          class="d-flex align-center"
          left="auto"
          right="auto"
          :height="$vuetify.breakpoint.xs ? '200px' : '100%'"
          ><v-icon class="d-flex" x-large color="primary"
            >mdi-image-area</v-icon
          ></v-img
        >
      </v-col>
      <v-col class="pb-0" sm="8" cols="12">
        <v-card v-if="!!event.trail" flat>
          <v-card-text
            class="pa-1 my-2"
            style="max-height: 60vh; overflow-y: auto"
          >
            <v-card class="my-2" elevation="3">
              <v-card-text class="pa-2">
                <v-row class="justify-space-between align-center">
                  <v-col md="8" sm="7" cols="6">
                    <div class="acme">{{ event.trail.trailName }}</div>
                    <div>
                      <strong>Dauer: </strong>
                      <span class="ml-3"> {{ event.trail.duration }}' </span>
                    </div>
                    <div>
                      <strong>Anz. Challenges: </strong>
                      <span class="ml-3" v-if="event.trail.challenges > 0">
                        {{ event.trail.challenges.length }} Stk.
                      </span>
                    </div>
                    <div>
                      <strong>Trail-Ort: </strong>
                      <span class="ml-3">
                        {{ event.trail.rest.location }}
                      </span>
                    </div>
                    <div>
                      <strong>Trail-Kurzbeschrieb: </strong>
                      <span class="ml-3">
                        {{ event.trail.rest.shortDescription }}
                      </span>
                    </div>
                  </v-col>
                  <v-col md="4" sm="5" cols="6" class="text-right pr-2 pl-0">
                    <v-dialog
                      max-width="300"
                      :retain-focus="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="fruit"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:default="deleteTrail">
                        <v-card>
                          <v-card-title class="py-2 fruit black--text">
                            ACHTUNG
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="deleteTrail.value = false"
                              icon
                              color="white"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text class="py-4"
                            >MÖCHTEST DU WIRKLICH DIESEN TRAIL VOM EVENT
                            ENTFERNEN?
                            <br />
                            <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT
                            WERDEN UND WIRD OHNE ZU SPEICHERN AUSGEFÜHRT.
                            <div class="d-flex justify-space-around my-3">
                              <v-btn
                                color="info"
                                :small="$vuetify.breakpoint.xs"
                                class="ma-2"
                                @click="deleteTrail.value = false"
                              >
                                Abbrechen
                              </v-btn>
                              <v-btn
                                color="primary"
                                :small="$vuetify.breakpoint.xs"
                                class="ma-2"
                                @click="deleteTrailFromEvent();deleteTrail.value = false"
                              >
                                Entfernen
                              </v-btn>
                            </div>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-text
            >Noch keine Trails zu diesem Event hinzugefügt</v-card-text
          >
        </v-card>
      </v-col>
      <v-col sm="8" cols="12">
        <v-card-actions class="justify-space-around pt-3 flex-wrap px-0">
          <v-dialog max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="fruit"
                class="br-md ma-1"
                :small="$vuetify.breakpoint.xs"
                :block="$vuetify.breakpoint.xs"
                v-bind="attrs"
                v-on="on"
                >Event löschen
              </v-btn>
            </template>
            <template v-slot:default="deleteEventModal">
              <v-card>
                <v-card-title class="py-2 fruit black--text">
                  ACHTUNG
                  <v-spacer></v-spacer>
                  <v-btn @click="deleteEventModal.value = false" icon color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="py-4"
                  >MÖCHTEST DU WIRKLICH DIESES EVENT LÖSCHEN?
                  <br />
                  <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.
                  <div class="d-flex justify-space-around my-3">
                    <v-btn
                      color="info"
                      :small="$vuetify.breakpoint.xs"
                      class="ma-2"
                      @click="deleteEventModal.value = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      @click="deleteEvent()"
                      color="primary"
                      :small="$vuetify.breakpoint.xs"
                      class="ma-2"
                      >Löschen
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>

          <v-btn
            @click="editEvent()"
            color="primary"
            class="br-md ma-1"
            :small="$vuetify.breakpoint.xs"
            :block="$vuetify.breakpoint.xs"
            >Speichern</v-btn
          >
        </v-card-actions>
        <v-card>
          <v-card-text class="pa-1 pa-sm-5">
            <v-form ref="editEvent">
              <v-card-text class="pb-2">
                <div class="my-2">
                  <label class="acme">*Event-Name</label>
                  <v-text-field
                    outlined
                    color="primary"
                    placeholder="Gib dem Event einen Namen."
                    v-model="event.eventName"
                    dense
                    hide-details
                    clearable
                    required
                    :rules="rules.requiredRules"
                  >
                  </v-text-field>
                </div>
                <div class="my-2">
                  <label class="acme">Kurzbeschreibung</label>
                  <v-textarea
                    outlined
                    rows="3"
                    color="primary"
                    placeholder="Füge dem Event eine Kurzbeschreibung hinzu."
                    v-model="event.rest.shortDescription"
                    dense
                    hide-details
                    clearable
                  >
                  </v-textarea>
                </div>
                <div class="my-2">
                  <label class="acme">*Event-Datum</label>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        prepend-icon="mdi-calendar"
                        dense
                        required
                        :rules="rules.requiredRules"
                        :value="$date.dateFormatted(event.date)"
                        clearable
                        placeholder="Wann findet das Event statt?"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="event.date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="event.date"
                      locale="de-CH"
                      no-title
                      :first-day-of-week="1"
                      @change="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div class="my-2">
                  <label class="acme">*Zeitfenster</label>
                  <div class="d-flex">
                    <div class="col my-0 py-0">
                      <label>Von</label>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="event.startTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="event.startTime"
                            hide-details
                            outlined
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                            required
                            :rules="rules.requiredRules"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu"
                          format="24hr"
                          v-model="event.startTime"
                          full-width
                          @click:minute="$refs.menu.save(event.startTime)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <div class="col my-0 py-0">
                      <label>Bis</label>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="event.endTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="event.endTime"
                            hide-details
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                            :rules="rules.requiredRules"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          format="24hr"
                          v-model="event.endTime"
                          full-width
                          @click:minute="$refs.menu2.save(event.endTime)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="acme">Bild</label>
                    <v-file-input
                      @change="onFileChange"
                      accept="image/png,image/jpg"
                      outlined
                      hide-details
                      placeholder="Füge ein Bild für dieses Event ein oder ersetze das Bestehende."
                      dense
                      clearable
                    ></v-file-input>
                  </div>
                </div>
              </v-card-text>
            </v-form>
            <v-card flat>
              <template>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12 pb-0">
                        <label class="acme">Anzahl Gruppen hinzufügen</label>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6 pt-0">
                        <v-text-field
                          class="mt-0 pt-0"
                          v-model="groupAmount"
                          :append-outer-icon="
                            groupAmount
                              ? 'mdi-account-multiple-plus'
                              : 'mdi-account-multiple-plus-off'
                          "
                          prepend-icon="mdi-account-group"
                          clearable
                          hide-details
                          type="number"
                          @click:append-outer="postGroups"
                          @click:clear="clearGroupAmount"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12 pb-0">
                        <div class="d-flex justify-space-between align-center">
                          <span class="pb-0 text-subtitle-2">
                            Insgesamt {{ event.players.length }} Gruppen
                          </span>
                          <v-dialog v-model="dialog" max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="my-2"
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left color="fruit">mdi-delete</v-icon
                                >Alle löschen
                              </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-title class="py-2 fruit black--text">
                                  ACHTUNG
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    @click="dialog.value = false"
                                    icon
                                    color="white"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text class="py-4"
                                  >MÖCHTEST DU WIRKLICH ALLE GRUPPEN LÖSCHEN?
                                  <br />
                                  <br />DIESE AKTION KANN NICHT RÜCKGÄNGIG
                                  GEMACHT WERDEN UND WIRD OHNE ZU SPEICHERN AUSGEFÜHRT.
                                  <div class="d-flex justify-space-around my-3">
                                    <v-btn
                                      color="info"
                                      :small="$vuetify.breakpoint.xs"
                                      class="ma-2"
                                      @click="dialog.value = false"
                                    >
                                      Abbrechen
                                    </v-btn>
                                    <v-btn
                                      @click="deleteGroupsByEvent()"
                                      color="primary"
                                      :small="$vuetify.breakpoint.xs"
                                      class="ma-2"
                                    >
                                      Löschen
                                    </v-btn>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </template>
                          </v-dialog>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </template>
              <v-card-text
                class="pa-1 mt-0 mb-2"
                style="max-height: 60vh; overflow-y: auto"
              >
                <v-card
                  class="my-2"
                  v-for="(group, idx) in event.players"
                  :key="'group_' + idx"
                  elevation="3"
                >
                  <group-card :group="group"></group-card>
                </v-card>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GroupCard from "../components/group-card";

export default {
  name: "eventEdit",
  components: { GroupCard },
  data() {
    return {
      groupAmount: null,
      dialog: false,
      rules: this.$store.getters.rules,
      menu: false,
      menu2: false,
      menu3: false,
      event: {
        players: [],
        rest: {},
      },
      groupForm: {},
    };
  },
  methods: {
    clearGroupAmount() {
      this.groupAmount = null;
    },
    onFileChange(e) {
      // TODO: reduce code duplication
      var file = e;

      if (file) {
        this.createImage(file); // just one file => TODO: multiple files
      } else {
        // else block needed because this function runs on every change => even when removing image
        delete this.event.rest.img;
      }
    },
    createImage(file) {
      // TODO: reduce code duplication
      var reader = new FileReader();

      reader.onload = () => {
        this.event.rest.img = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file); // base64
      }

      reader.onerror = (e) => {
        console.error(e);
      };
    },
    async postGroups() {
      if (this.groupAmount < 20 && this.groupAmount > 0) {
        await this.$api.postGroups(this.$route.params.id, this.groupAmount);
        this.getEvent();
      } else {
        alert("Bitte eine ganze Zahl zwischen 1-20 eingeben.");
      }
      this.clearGroupAmount();
    },
    async deleteTrailFromEvent() {
      //this.event.trail = null;
      await this.$api.deleteTrailFromEvent(this.$route.params.id);
      this.getEvent();
    },
    async deleteGroupsByEvent() {
      await this.$api.deleteGroupsByEvent(this.$route.params.id);
      this.getEvent();
      this.dialog = false;
    },
    async deleteEvent() {
      await this.$api.deleteEvent(this.$route.params.id);
      this.$router.push({ name: "Events" });
    },
    async editEvent() {
      if (this.$refs.editEvent.validate()) {
        var preparedEventJson = Object.assign({}, this.event);
        preparedEventJson.date = this.$date.formatDateForBackend(
          preparedEventJson.date
        );
        await this.$api.editEvent(this.$route.params.id, preparedEventJson);
        // in order to immediately fetch just updated event

        var res = await this.$api.getEvent(this.$route.params.id);
        res.data = this.prepareDateForRender(res.data);
        this.event = res.data;
      } else {
        alert("Bitte alle benötigten Felder ausfüllen.");
      }
    },
    async getEvent() {
      var res = await this.$api.getEvent(this.$route.params.id);
      res.data = this.prepareDateForRender(res.data);
      this.event = res.data;
    },
    prepareDateForRender(data) {
      data.date = this.$date.formatDateForCalendar(data.date);
      data.startTime = this.$date.formatTime(data.startTime);
      data.endTime = this.$date.formatTime(data.endTime);
      return data;
    },
  },
  created() {
    this.getEvent();
  },
};
</script>

<style scoped>
</style>